<template lang="html">
  <IxVectorLayer
    :features="errorFeatures"
    :vector-style="vectorStyle"
  />
</template>

<script>
import IxVectorLayer from '@components/map/IxVectorLayer'
import {findKinks, findIntersections} from 'src/js/helpers/geojson/errors'
import {error} from '@frs/map-styles'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    features: Array
  },
  computed: {
    errorFeatures () {
      const kinks = findKinks(this.features)
      const intersections = findIntersections(this.features)

      return kinks.concat(intersections)
    },
    vectorStyle () {
      return error
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
