<template lang="html">
  <FrsFieldSelection :value="selectedFieldIds" :mixed-selection-filter="mixedSelectionFilter" @toggle="onToggle">
    <template #default="{field}">
      <CultivationTable compact :field-id="field.id" @click.native.stop />
    </template>
    <template #subtitle="{field}">
      <IxRes v-if="selectionSummaryByFieldId[field.id].everythingSelected">
        frs.actionPlanning.hint.everythingSelected
      </IxRes>
      <template v-else>
        <IxRes
          :context="selectionSummaryByFieldId[field.id]"
        >
          frs.actionPlanning.hint.selectedCultivationCount
        </IxRes>
        <IxRes v-if="selectionSummaryByFieldId[field.id].remainingFieldSelected">
          frs.actionPlanning.hint.remainingFieldSelected
        </IxRes>
      </template>
    </template>
    <template #avatar="{fieldId}">
      <CultivationAvatar :field-id="fieldId" />
    </template>
  </FrsFieldSelection>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'

import CultivationAvatar from './CultivationAvatar'
import CultivationTable from './wizard/widgets/CultivationTable'

export default {
  components: {
    CultivationTable,
    FrsFieldSelection,
    CultivationAvatar
  },
  computed: {
    ...mapState('fieldRecordSystem/action/editBuffer', [
      'id',
      'fieldIds'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/action/editBuffer', [
      'selectionSummaryByFieldId'
    ]),
    selectedFieldIds () {
      return this.fieldIds.filter(fieldId => {
        if (this.fieldId) return true

        return this.selectionSummaryByFieldId[fieldId].somethingSelected
      })
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/action/editBuffer', [
      'toggleFieldId'
    ]),
    onToggle (fieldId) {
      if (this.id) return

      this.toggleFieldId(fieldId)
    },
    mixedSelectionFilter (fieldId) {
      const summary = this.selectionSummaryByFieldId[fieldId]

      return summary.somethingSelected && !summary.everythingSelected
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
