<template lang="html">
  <div class="entity-preview-container">
    <ListCard
      :title="entity.name"
      :show-avatar="isField"
      :selected="selected" @click.native="toggleNode(entity)"
    >
      <template #subtitle>
        <IxRes v-if="subtitle.text">Areas.FieldRecordSystem.SR_FieldRecordSystem.{{ subtitle.text }}</IxRes> {{ subtitle.extend }}
      </template>
      <EntityPreviewAvatar
        v-if="isField" slot="avatar"
        :field-id="null"
        :field-feature="entity.feature" :selected="selected"
      />
      <template slot="addon">
        <div>
          <QuickActions>
            <QuickActionIcon
              icon="search-plus" :disabled="!entity.feature"
              :title="SR_FieldRecordSystem.Focus"
              @click="onFocusClick(entity)"
            />
          </QuickActions>
        </div>
      </template>
    </ListCard>
    <div v-if="entity.children" class="children">
      <EntityPreviewCard v-for="child in entity.children" :key="child.id" :entity="child" />
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import area from '@turf/area'

import {mapResources} from '@helpers/vuex'

import ListCard from '@components/card/ListCard'
import QuickActions from '@components/card/QuickActions'
import QuickActionIcon from '@components/card/QuickActionIcon'

import EntityPreviewAvatar from './EntityPreviewAvatar'

export default {
  name: 'EntityPreviewCard',
  components: {
    ListCard,
    QuickActions,
    QuickActionIcon,
    EntityPreviewAvatar
  },
  props: {
    entity: Object
  },
  computed: {
    ...mapState('fieldRecordSystem/entityImport', [
      'selectedIds'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    isField () {
      return this.entity.type === 'field'
    },
    subtitle () {
      return this.entity.type === 'field'
        ? {text: null, extend: `ca. ${this.$i18n.format(this.area, 'f2')} ha`}
        : {text: 'TotalFieldArea', extend: ' ca. ' + this.$i18n.format(this.area, 'f2') + ' ha'}
    },
    area () {
      return this.getAreaRecursive(this.entity)
    },
    selected () {
      return this.selectedIds.includes(this.entity.id)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapActions('fieldRecordSystem/entityImport', [
      'toggleNode'
    ]),
    onFocusClick (entity) {
      this.focus(entity.feature)
    },
    getAreaRecursive (entity) {
      return entity.children
        ? entity.children.reduce((total, child) => total + this.getAreaRecursive(child), 0)
        : entity.feature
          ? area(entity.feature) / 10000
          : 0
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-preview-container {
  cursor: pointer;
}
.entity-preview-container:not(:last-child) {
  margin-bottom: 5px;
}
.children {
  padding-left: 10px;
}
</style>
