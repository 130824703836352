<template>
  <div>
    <div style="margin: 1em;">
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EntityRelocationIntro1</IxRes>
      </p>
      <p>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EntityRelocationIntro2</IxRes>
      </p>
    </div>

    <div class="hor">
      <Tree
        :nodes="leftNodes" class="box-content tree-box"
        :options="{id: 'leftTree'}"
        @initialized="left = $event"
      />

      <div class="hor centered">
        <i class="fa fa-exchange fa-3x text-muted" />
      </div>

      <Tree
        :nodes="rightNodes" class="box-content tree-box"
        :options="{id: 'rightTree'}"
        @initialized="right = $event"
      />

      <div class="box-content">
        <h4>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ListOfChanges</IxRes>
        </h4>
        <ListCard
          v-for="(item, index) in history" :key="index"
          :title="item.NodeText"
          show-details
        >
          <div>{{ item.OldParentText }} <i class="fa fa-arrow-right" /> {{ item.NewParentText }}</div>
        </ListCard>
      </div>
    </div>

    <div class="vert-flex">
      <div class="safety-warning">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.RelocationPermanentChangeWarning</IxRes>
      </div>

      <div>
        <IxButton
          save large
          :disabled="history.length === 0"
          @click="$emit('save', history)"
        >
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SaveChanges</IxRes>
        </IxButton>
        <IxButton cancel large @click="$emit('cancel')">
          <IxRes>Common.SR_Common.Cancel</IxRes>
        </IxButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import {makeTreeNonCircular} from '@frs/helpers/tree'
import {applyRecursive, findParent} from 'src/coffee/controls/tree/helpers'

import Tree from '@components/nav/Tree'
import ListCard from '@components/card/ListCard'
import IxButton from '@components/IxButton'

export default {
  components: {
    Tree,
    ListCard,
    IxButton
  },
  props: {
    orgUnits: Array
  },
  data () {
    return {
      left: null,
      right: null,

      leftNodes: [],
      rightNodes: [],
      history: [],
      caching: {
        currentTarget: null,
        result: false,
        sourceParentName: null
      },
      initialized: false
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem/navigation', [
      'parentLookup'
    ]),
    OrgUnitsWithoutFields () {
      const recursiveFilter = (entities) => entities.map(entity => entity.hasOwnProperty('children')
        ? {
          children: recursiveFilter(entity.children),
          id: entity.id,
          name: entity.name,
          type: entity.type,
          visibleOnly: entity.visibleOnly
        }
        : entity.type !== 'field'
          ? entity
          : null).filter(x => x)

      const clone = JSON.parse(JSON.stringify(this.orgUnits))

      return recursiveFilter(clone)
    }
  },
  methods: {
    nodeFromId (id) {
      if (id.startsWith('l')) {
        return this.left.getNode('l' + id.slice(1))
      }
      return this.right.getNode('r' + id.slice(1))
    },

    rebuildBoth () {
      // prevents timing issues during initialization
      if (this.left.getAllNodes().length === 0 || this.right.getAllNodes().length === 0) {
        return
      }

      this.left.rebuildTree()
      this.right.rebuildTree()
    },
    canDrop (event, nodes, isSourceNode, source, isTargetNode, target) {
      // cannot move visible only entities
      if (source.entity.visibleOnly) {
        return false
      }

      // cannot move root entities
      if (!this.parentLookup[source.entity.id]) {
        return false
      }

      if (target === this.caching.currentTarget) {
        return this.caching.result
      }

      const targetLeft = target.id.startsWith('l_')
      const targetRight = target.id.startsWith('r_')
      let result =
        isSourceNode && (targetRight || targetLeft) &&
        source.id.slice(2) !== target.id.slice(2)

      if (result && source.id[0] !== target.id[0]) {
        let sourceIsAncestorOfTarget = false
        applyRecursive((this.left.getNode('l' + source.id.slice(1)).children || []), node => {
          if (node.id.slice(1) === target.id.slice(1)) {
            sourceIsAncestorOfTarget = true
          }
        })
        result = result && !sourceIsAncestorOfTarget

        const tree = target.id.startsWith('l') ? this.left : this.right
        const $target = $(target)

        // make target expand on drop hover
        if (!$target.data('expand-scheduled')) {
          const f = () => {
            if (!tree.getNode(target.id).isExpanded) {
              tree.toggleNode(target.id)
            }
            $target.data('expand-scheduled', false)
          }

          const timeout = window.setTimeout(f, 600)
          $target.data('expand-scheduled', true)

          $target.one('mouseleave', () => {
            window.clearTimeout(timeout)
            $target.data('expand-scheduled', false)
          })
        }
      }

      // check target for correct template
      if (result) {
        const sourceNode = this.nodeFromId(source.id)
        const targetNode = this.nodeFromId(target.id)
        result = targetNode.entity.type === 'org' &&
          !sourceNode.entity.visibleOnly &&
          !targetNode.entity.visibleOnly
      }

      // cache result
      this.caching.currentTarget = target
      this.caching.result = result
      this.caching.sourceParentName = '' // this.getParentNodeName(sourceNode) // TODO fix performance, findParent is expensive -> use precomputed parent lookup

      return result
    },
    dropped (event, nodes, isSourceNode, source, isTargetNode, target) {
      const sourceNode = this.nodeFromId(source.id)
      const newParentNode = this.nodeFromId(target.id)

      // get node from source and target
      const leftSourceNode = this.left.getNode('l' + source.id.slice(1))
      const rightSourceNode = this.right.getNode('r' + source.id.slice(1))

      const leftTargetNode = this.left.getNode('l' + target.id.slice(1))
      const rightTargetNode = this.right.getNode('r' + target.id.slice(1))

      // remove source node from source and target tree
      if (leftSourceNode) {
        this.left.removeNode(leftSourceNode.id)
        leftSourceNode.liClass += ' text-info'
      }
      if (rightSourceNode) {
        this.right.removeNode(rightSourceNode.id)
        rightSourceNode.liClass += ' text-info'
      }

      // add source node to new parent in source and target tree
      if (leftTargetNode && leftSourceNode) {
        leftTargetNode.children.push(leftSourceNode)
      } else if (leftTargetNode && rightSourceNode) {
        leftTargetNode.children.push(rightSourceNode)
      }

      if (rightTargetNode && rightSourceNode) {
        rightTargetNode.children.push(rightSourceNode)
      } else if (rightTargetNode && leftSourceNode) {
        rightTargetNode.children.push(leftSourceNode)
      }

      this.rebuildBoth(source, target)

      const historyEntry = {
        EntityId: source.id.slice(2),
        NewParentId: target.id.slice(2),
        NodeText: sourceNode.text,
        NewParentText: newParentNode.text,
        OldParentText: this.caching.sourceParentName
      }

      this.$emit('event', 'dropped', historyEntry)

      this.history.push(historyEntry)
    },
    getParentNodeName (node) {
      return (findParent(this.left.getAllNodes(), node.id) || {text: 'Root'}).text
    },
    makeNodes (prefix) {
      const nodes = makeTreeNonCircular(this.OrgUnitsWithoutFields)
      applyRecursive(nodes, (node) => {
        node.id = prefix + node.entity.id
      })
      return nodes
    },
    initializeTreeDataFromProp () {
      this.leftNodes = this.makeNodes('l_')
      this.rightNodes = this.makeNodes('r_')
    },
    initializeControl () {
      if (!(this.left && this.right)) {
        return
      }

      if (this.initialized) {
        this.$emit('initControl', true)
        return
      }

      this.initialized = true

      this.initializeTreeDataFromProp()

      const optionOverrides = {
        canDrop: this.canDrop,
        dropped: this.dropped,
        enableDnd: true
      }

      Object.assign(this.left.options, optionOverrides)
      Object.assign(this.right.options, optionOverrides)

      this.rebuildBoth()

      this.$emit('initControl', true)
    },
    reset () {
      this.initializeTreeDataFromProp()
      this.history = []
    }
  },
  watch: {
    orgUnits: {
      immediate: true,
      handler: 'reset'
    },
    left () {
      this.initializeControl()
    },
    right () {
      this.initializeControl()
    }
  }
}
</script>

<style lang="scss" scoped>
.hor {
  display: flex;
}

.centered {
  align-items: center;
}

.vert-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-content {
  flex: 1 0 auto;
  margin: 1em;
}

.tree-box {
  border: 1px solid black;
  min-width: 290px;
}

.safety-warning {
  border: 1px solid #cf2a27;
  background-color: #ea9999;
  padding: 1em;
  width: 400px;
  margin:1em;
}
</style>
