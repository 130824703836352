<template lang="html">
  <ListCard
    :show-details="!detailsCollapsed"
    :selected="selected" :locked="orgUnit.visibleOnly"
  >
    <template #title>
      <slot name="title">
        {{ orgUnit.name }}
      </slot>
    </template>

    <template #addon>
      <div class="menu">
        <QuickActions>
          <QuickActionIcon
            v-if="$listeners.focus"
            icon="search-plus"
            :title="navigationTranslations.focus"
            @click="$emit('focus', orgUnit.id)"
          />
        </QuickActions>
        <IxCollapsePanel
          v-if="$slots.default" v-model="detailsCollapsed"
        >
          <template #expand-text>
            <IxRes>Common.SR_Common.navigation.displayOptions</IxRes>
          </template>
          <template #collapse-text>
            <IxRes>Common.SR_Common.navigation.hideOptions</IxRes>
          </template>
        </IxCollapsePanel>
      </div>
    </template>

    <slot />

    <template #subtitle>
      <div>
        <template v-for="(count, type, i) in childCountByType">
          <span v-if="i !== 0" :key="`${type}-delimiter`">,&nbsp;</span>
          <IxRes :key="type" :context="{0: count}">
            Common.SR_Common.navigation.childCount_{{ type }}_{{ count === 1 ? 'singular' : 'plural' }}
          </IxRes>
        </template>
      </div>
    </template>
  </ListCard>
</template>

<script>
import {mapResources} from '@helpers/vuex'
import {mapUiFlags} from 'src/vue/areas/field-record-system/helpers/ui'
import {filters} from 'src/js/helpers/tree.js'

import ListCard from '@components/card/ListCard'
import QuickActions from '@components/card/QuickActions'
import QuickActionIcon from '@components/card/QuickActionIcon'
import IxCollapsePanel from '@components/ix/IxCollapsePanel'

export default {
  components: {
    QuickActions,
    QuickActionIcon,
    ListCard,
    IxCollapsePanel
  },
  props: {
    orgUnit: Object,
    selected: Boolean,
    focusTarget: {}
  },
  data () {
    return {
      detailsCollapsed: false
    }
  },
  computed: {
    ...mapResources({
      navigationTranslations: 'Common.SR_Common.navigation'
    }),
    ...mapUiFlags([
      'dashboard.hideEmptyOrgs'
    ]),
    subOrgUnits () {
      return this.orgUnit.children.filter(x => x.type === 'org').length
    },
    fields () {
      return this.orgUnit.children.filter(x => x.type === 'field').length
    },
    childCountByType () {
      const childCountByType = {}

      const children = this.hideEmptyOrgs
        ? this.orgUnit.children.filter(filters.hasFieldsSomewhere)
        : this.orgUnit.children

      for (const child of children || []) {
        if (!childCountByType[child.type]) {
          childCountByType[child.type] = 0
        }
        childCountByType[child.type]++
      }

      return childCountByType
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  display:flex; flex-direction: column; height: 100%; justify-content: space-between; align-items: flex-end;
}
.details {
  cursor: pointer;
}
</style>
