<template lang="html">
  <FrsFeatureAvatar>
    <polygon
      v-for="(polygon, index) in borderPolygons" :key="index"
      class="border"
      :stroke="borderStroke"
      :fill="borderFill"
      :points="polygon"
    />
  </FrsFeatureAvatar>
</template>

<script>
import ColorHash from 'color-hash'
import {mapState} from 'vuex'

import {withAlpha} from '@helpers/color'
import {toSvgPolygons} from '@helpers/geojson'

import FrsFeatureAvatar from '@frs/components/base/FrsFeatureAvatar'

const hash = new ColorHash()

export default {
  components: {
    FrsFeatureAvatar
  },
  props: {
    fieldFeature: Object,
    selected: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem/entityImport', {
      colorProperty: state => state.columnMapping.colorProperty
    }),
    borderPolygons () {
      return toSvgPolygons(this.fieldFeature)
    },
    colorKey () {
      return this.fieldFeature.properties ? this.fieldFeature.properties[this.colorProperty] : null
    },
    colorValue () {
      return hash.hex(this.colorKey)
    },
    borderStroke () {
      return this.colorKey ? hash.hex(this.colorKey) : '#bbb'
    },
    borderFill () {
      return this.selected ? withAlpha(this.borderStroke, 0.75) : 'transparent'
    }
  },
  mounted () {
    this.animate = true
  }
}
</script>

<style lang="scss" scoped>
.border {
  stroke-width: 4;
}
</style>
