<template lang="html">
  <div style="position: relative;">
    <FrsFeatureAvatar>
      <template v-if="polygons">
        <polygon
          v-for="(polygon, index) in polygons" :key="index"
          :points="polygon"
          stroke="black"
          stroke-width="2"
          fill="#dddddd"
        />
      </template>
    </FrsFeatureAvatar>
    <FrsLoadingIndicator v-if="loading" small :requests="['orgUnit.geometries']" />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {toSvgPolygons} from '@helpers/geojson'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import FrsFeatureAvatar from '@frs/components/base/FrsFeatureAvatar'

export default {
  components: {
    FrsFeatureAvatar,
    FrsLoadingIndicator
  },
  props: {
    fieldId: String,
    loading: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem/navigation', [
      'simplifiedGeometries'
    ]),
    polygons () {
      return toSvgPolygons(this.simplifiedGeometries[this.fieldId])
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
