<template lang="html">
  <div>
    <div v-for="(fields, name) in groups" :key="name">
      <TagItem
        :group="name" :field-count="fields.length"
        :selected="isCurrentTag(name)"
        @click.native="selectOnlyTag(name)"
      />
      <div class="list-group">
        <FieldItem
          v-for="field in fields" :key="field.id"
          :field="field"
          :selected="isCurrentField(field.id)" @click.native="selectFieldAndTag(field.id, name)"
        />
      </div>
    </div>

    <IxVectorLayer
      :features="features"
      :vector-style="styles.field"
      :cluster-style="styles.fieldCluster"
      layer-id="fieldsForTag"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {fromFields} from '@frs/helpers/geojson/create-features'
import {field, fieldCluster} from '@frs/map-styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

import TagItem from '../items/TagItem'
import FieldItem from '../items/FieldItem'

import DisableDefaultMapLayersMixin from '@frs/mixins/DisableDefaultMapLayersMixin'

export default {
  components: {
    IxVectorLayer,
    TagItem,
    FieldItem
  },
  mixins: [
    DisableDefaultMapLayersMixin
  ],
  props: [
    'groups' // fieldsByTag
  ],
  computed: {
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    ...mapState('fieldRecordSystem/navigation', {
      location: state => state.location
    }),
    features () {
      const fields = this.location.tag
        ? this.groups[this.location.tag]
        : Object.values(this.groups).reduce((all, fields) => all.concat(fields), [])

      return fromFields(fields, this.fieldData)
    },
    styles () {
      return {
        field,
        fieldCluster
      }
    }
  },
  methods: {
    getFirstTag (fieldId) {
      return Object.keys(this.groups).find(name => this.groups[name].find(x => x.id === fieldId))
    },
    selectOnlyTag (tag) {
      this.$router.push({name: 'tagDashboard', params: {tag}})
    },
    selectFieldAndTag (fieldId, tag) {
      this.$router.push({name: 'tagFieldDetails', params: {tag, fieldId}})
    },
    isCurrentField (fieldId) {
      return fieldId === this.location.fieldId
    },
    isCurrentTag (name) {
      return !this.location.fieldId && this.location.tag === name
    }
  },
  created () {
    if (this.location.fieldId) {
      const tag = this.getFirstTag(this.location.fieldId)

      if (tag) {
        this.selectFieldAndTag(this.location.fieldId, tag)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  margin-bottom: 5px;
}
.group-title {
  cursor: pointer;

  &.active {
    color: red;
    font-weight: bold;
  }
}
.list-group {
  width: 95%;
  margin-left: 5%;
}
</style>
