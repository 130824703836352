<template lang="html">
  <div class="rocket-header">
    <div v-if="currentOrg" class="parent-info">
      <div class="panel panel-default parent-orgunit" @click="$emit('navigate', parentOrg || null)">
        <div class="header">
          <span v-if="parentOrg">{{ parentOrg.name }}</span>
          <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.AllOrganizations</IxRes>
        </div>
      </div>
      <i class="fa fa-angle-down fa-2x" />
    </div>

    <div class="current-info" :class="{'has-current': currentOrg}">
      <OrgUnitItem
        v-if="currentOrg" class="current-org-unit"
        :org-unit="currentOrg"
        :selected="currentOrg.id === selectedId"
        @click.native="$emit('navigate', currentOrg)"
        v-on="inheritedListeners"
      >
        <slot />
      </OrgUnitItem>
      <div v-else class="panel panel-default all-orgunits">
        <div class="header">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AllOrganizations</IxRes>
        </div>

        <slot />
      </div>
    </div>

    <i class="fa fa-angle-down fa-2x" />
  </div>
</template>

<script>

import OrgUnitItem from './OrgUnitItem'

export default {
  components: {
    OrgUnitItem
  },
  props: {
    parentOrg: Object,
    currentOrg: Object,
    selectedId: String
  },
  computed: {
    inheritedListeners () {
      const listeners = {}

      if (this.$listeners.focus) {
        listeners.focus = this.$listeners.focus
      }

      return listeners
    }
  }
}
</script>

<style lang="scss" scoped>
.rocket-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  padding-top: 10px;
}
.parent-orgunit, .all-orgunits {
  width: 100%;
  padding: 2px 5px;
  font-size: 1.2em;
  margin: 0;

  .header {
    text-align: center;
  }
}
.parent-info, .current-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  &.has-current {
    cursor: pointer;
  }
}
.parent-orgunit {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    border-color: #a0d9ef;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.075);
  }

  transition: all 0.15s;
}
.all-orgunits .header {
  cursor: default;
  opacity: 0.5;
}
.current-org-unit {
  width: 100%;
}
</style>
