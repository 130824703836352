<template lang="html">
  <div v-if="$can('See.Frs.ExperimentalFeatures')" class="experimental-feature">
    <div class="ribbon">
      BETA
    </div>

    <slot />
  </div>
</template>

<script>
import PermissionMixin from '@mixins/PermissionMixin'

export default {
  mixins: [
    PermissionMixin
  ]
}
</script>

<style lang="scss" scoped>
.experimental-feature {
  border: 1px solid #39d;
  padding: 0.25em;

  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.ribbon {
  width: 6rem;
  background: #39d;
  position: absolute;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 1px;
  color: #f0f0f0;

  top: 0.5rem;
  right: -2rem;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
