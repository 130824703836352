<template lang="html">
  <div class="search-results" style="position: relative;">
    <template v-if="searchResult">
      <h4>
        <IxRes>Common.SR_Common.SearchResultFor</IxRes> '{{ searchText }}'
      </h4>
      <HelpBox type="warning">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SearchResultHint</IxRes>
      </HelpBox>
      <transition-group
        name="slide" tag="div"
        class="entity-list"
        :class="direction" mode="out-in"
      >
        <template v-for="(entity, i) in firstResults">
          <div v-if="i === 0 || parentLookup[entity.id] !== parentLookup[firstResults[i - 1].id]" :key="entity.id + '_parent'" class="muted">
            <span v-if="parentLookup[entity.id]">{{ entityLookup[parentLookup[entity.id]].name }}</span>
            <IxRes v-else>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoParentOrgUnit</IxRes>
          </div>
          <FieldItem
            v-if="entity.type === 'field'" :key="entity.id"
            :field="entity" :show-code="showFieldCode"
            :selected="selectedField === entity" @click.native="selectedField = entity"
          />
          <OrgUnitItem
            v-else :key="entity.id"
            :org-unit="entity"
            :selected="selectedOrgUnit === entity" @click.native="selectedOrgUnit = entity"
          />
        </template>
        <div v-if="moreResults" key="more-results" class="alert alert-info">
          <IxRes :context="{0: moreResults}">Common.SR_Common.MoreResults</IxRes>
        </div>
      </transition-group>
    </template>
    <div v-else class="alert alert-danger">
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true" /> <IxRes>Common.SR_Common.NothingFound</IxRes>
    </div>

    <FrsLoadingIndicator v-if="searching" small :loading="searching" />
  </div>
</template>

<script>
// TODO refactor using a new RocketSearchResults base, same for dlg-cert SearchResults
import {mapState, mapGetters, mapMutations} from 'vuex'

import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import HelpBox from '@components/help/HelpBox'
import OrgUnitItem from '@components/nav/OrgUnitItem'

import FieldItem from '../items/FieldItem'

export default {
  components: {
    OrgUnitItem,
    FieldItem,
    HelpBox,
    FrsLoadingIndicator
  },
  data: function () {
    return {
      direction: 'down'
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', [
      'userSettings'
    ]),
    ...mapState('fieldRecordSystem/navigation', {
      location: state => state.location,
      searchText: state => state.search.text,
      searchResult: state => state.search.result,
      searching: state => state.search.searching
    }),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup',
      'parentLookup'
    ]),
    selectedOrgUnit: {
      get () {
        return this.entityLookup[this.location.orgUnitId]
      },
      set (orgUnit) {
        const orgUnitId = orgUnit.id
        this.$router.push({name: 'orgDashboard', params: {orgUnitId}})
        this.setSearchText('')
      }
    },
    selectedField: {
      get () {
        return this.entityLookup[this.location.fieldId]
      },
      set (field) {
        const fieldId = field.id
        this.$router.push({name: 'fieldDashboard', params: {fieldId}})
        this.setSearchText('')
      }
    },
    showFieldCode () {
      return !!this.userSettings.ui['dashboard.showFieldCodes']
    },
    firstResults () {
      return this.searchResult ? this.searchResult.slice(0, 30) : []
    },
    moreResults () {
      return this.searchResult ? this.searchResult.length - this.firstResults.length : 0
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/navigation', [
      'setSearchText'
    ])
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  display: flex;
  flex-direction: column;
  min-height: 0; // firefox scrollbar issue
}

.alert {
  display: flex;
  align-items: center;

  .fa {
    margin-right: 10px;
    font-size: 1.5em;
  }
}

.entity-list {
  overflow-y: auto;
  overflow-x: hidden;

  .list-card {
    width: 95%;
    margin-left: 5%;
  }

  .muted {
    color: gray;
  }
}
</style>
