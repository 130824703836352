var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleTable',{staticClass:"cultivation-table",attrs:{"columns":_vm.cultivationColumns,"data":_vm.cultivations},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.cultivationTypeTranslations[row.type])+"\n  ")]}},{key:"cropUsageId",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"cultivation-item"},[(row.cropUsageId && row.cropUsageId !== '00000000-0000-0000-0000-000000000000')?_c('IxRes',[_vm._v("masterData.crops.usage."+_vm._s(row.cropUsageId)+"_name")]):_vm._e(),(row.underseedMixtureId)?_c('IxRes',{attrs:{"context":{mixture: _vm.mixtures[row.underseedMixtureId].name}}},[_vm._v("frs.cultivation.underseedMixture")]):_vm._e()],1)]}},{key:"dateTimeRange",fn:function(ref){
var ref_value = ref.value;
var start = ref_value.start;
var end = ref_value.end;
return [_vm._v("\n    "+_vm._s(_vm.$i18n.format(start, 'date'))+" - "+_vm._s(_vm.$i18n.format(end, 'date'))+"\n  ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }