<template lang="html">
  <div class="workflow-left">
    <div class="workflow-left-title">
      <IxRes>Common.SR_Common.{{ `Workflow_${workflow.name}_LeftTitle` }}</IxRes>
    </div>
    <div class="workflow-left-content">
      <PortalTarget v-if="workflow.usePortals" name="leftSidebar" class="portal-target" />
      <component :is="workflow.left" v-else />
    </div>
    <div class="workflow-left-status">
      <IxRes>Common.SR_Common.{{ `Workflow_${workflow.name}_LeftStatus` }}</IxRes>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import ActionPlanningFieldSelection from '@frs/components/action/planning/ActionPlanningFieldSelection'
import SamplingOrderCreationFieldSelection from '@frs/components/basic-fertilization/order-creation/SamplingOrderCreationFieldSelection'
import SamplingOrderPlanningFieldSelection from '@frs/components/basic-fertilization/order-planning/SamplingOrderPlanningFieldSelection'
import CultivationPlanningFieldSelection from '@frs/components/cultivation-planning/CultivationPlanningFieldSelection'
import BasicFertilizationApplicationMapFieldSelection from '@frs/components/basic-fertilization/application-maps/BasicFertilizationApplicationMapFieldSelection'
import ApplicationMapPreviewFieldSelection from '@frs/components/basic-fertilization/application-maps/preview/ApplicationMapPreviewFieldSelection'
import EntityImportEntitySelection from '@frs/components/entity-import/EntityImportEntitySelection'
import BasicFertilizationFieldSelection from '@frs/components/basic-fertilization/BasicFertilizationFieldSelection'
import NutrientMapsFieldSelection from '@frs/components/basic-fertilization/nutrient-maps/NutrientMapsFieldSelection'

export default {
  components: {
    ActionPlanningFieldSelection,
    SamplingOrderCreationFieldSelection,
    SamplingOrderPlanningFieldSelection,
    CultivationPlanningFieldSelection,
    BasicFertilizationApplicationMapFieldSelection,
    EntityImportEntitySelection,
    BasicFertilizationFieldSelection,
    NutrientMapsFieldSelection,
    ApplicationMapPreviewFieldSelection
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      workflow: state => state.ui.workflow
    })
  }
}
</script>

<style lang="scss" scoped>
.workflow-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
.workflow-left-title {
  padding: 5px;
  font-size: 3em;
  font-weight: 100;
}
.workflow-left-content, .portal-target {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}
.workflow-left-status {
  margin-top: auto;
  padding: 5px;
  font-size: 0.75em;
}

.portal-target {
  width: 100%;
  height: 100%;
}
</style>
