<template lang="html">
  <div class="bf-field-selection">
    <FrsFieldSelection
      v-if="Object.keys(mapIdsByFieldId).length > 0"
      :value="[selectedFieldId]"
      :filter="filterFields"
      @toggle="onToggle"
    >
      <template #subtitle="{field}">
        <span v-if="mapIdsByFieldId[field.id]" class="subtitle" :title="$i18n.translate('bf.fieldItem.countApplicationMapPreviewsCounter')">
          <IxRes v-if="mapIdsByFieldId[field.id].length > 1" :context="{count: mapIdsByFieldId[field.id].length}">bf.fieldItem.multipleMapsForPostProcessing</IxRes>
          <IxRes v-else :context="{count: mapIdsByFieldId[field.id].length}">bf.fieldItem.singleMapForPostProcessing</IxRes>
        </span>
      </template>
    </FrsFieldSelection>
    <div v-else class="info-box">
      <HelpBox>
        <IxRes>bf.applicationMap.preview.helpBox.info.noFieldsWithMapsAvailable</IxRes>
      </HelpBox>
    </div>
    <FrsLoadingIndicator :requests="['orgUnit.bf.applicationMaps.preview']" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    FrsFieldSelection,
    FrsLoadingIndicator,
    HelpBox
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization', {
      simplifiedGeometries: state => state.data.simplifiedGeometries
    }),
    ...mapState('fieldRecordSystem/basicFertilization/applicationMapsPreview', {
      mapIdsByFieldId: state => state.data.applicationMapIdsByFieldId
    }),
    ...mapFormFields('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'selectedFieldId'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapMutations('fieldRecordSystem/basicFertilization/applicationMapsPreview', [
      'setSelectedFieldId',
      'resetUi'
    ]),
    filterFields ({id}) {
      return Object.keys(this.mapIdsByFieldId).includes(id)
    },
    onToggle (id) {
      this.setSelectedFieldId(id)
    }
  },
  watch: {
    selectedFieldId: {
      immediate: true,
      handler (id) {
        if (!id) return
        this.focus(this.simplifiedGeometries[id])
        this.resetUi()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bf-field-selection {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  .info-box {
    padding: 5px;
  }
}

</style>
