<template lang="html">
  <div class="settings-component">
    <SimpleSwitch v-model="snapping">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Snapping</IxRes>
    </SimpleSwitch>
    <SimpleSwitch v-model="autoFit">
      <IxRes>Common.SR_Common.AdjustViewFrameAutomatically</IxRes>
    </SimpleSwitch>
    <SimpleSwitch v-model="validateWhileChange">
      <IxRes>Common.SR_Common.ActiveValidation</IxRes>
    </SimpleSwitch>
    <SimpleSwitch v-model="showGeometryErrors">
      <IxRes>webgis.labels.showGeometryErrors </IxRes>
    </SimpleSwitch>
    <FrsExperimentalFeature>
      <SimpleSwitch v-model="roads">
        <IxRes placeholder>Areas.FieldRecordSystem.SR_FieldRecordSystem.Roads</IxRes>
      </SimpleSwitch>
      <IxWFSLayer
        v-if="roads"
        url="/api/v2/entities/fields/geodata/roads"
        :layer-style="roadStyle"
        :max-resolution="10"
      />
    </FrsExperimentalFeature>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {roads} from '@frs/map-styles'

import SimpleSwitch from '@components/forms/SimpleSwitch'
import FrsExperimentalFeature from '@frs/components/base/FrsExperimentalFeature'

import IxWFSLayer from '@components/map/IxWFSLayer'

export default {
  components: {
    SimpleSwitch,
    IxWFSLayer,
    FrsExperimentalFeature
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      selectedFeatures: state => state.editing.selection,
      value: state => state.editing.mode,
      selection: state => state.editing.selection
    }),
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.validateWhileChange',
      'editing.snapping',
      'editing.autoFit',
      'editing.roads',
      'editing.showGeometryErrors'
    ]),
    roadStyle () {
      return roads
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.settings-component {
  display: flex;
  flex-direction: column;
  padding: 10px;

  > * {
    margin-top: 16px;
  }
}
</style>
