<template lang="html">
  <IxVectorLayer
    :features="resultFeatures"
    :vector-style="vectorStyle"
    fast
  />
</template>

<script>
import {unByKey} from 'ol/Observable'
import Draw from 'ol/interaction/Draw'

import booleanCrosses from '@turf/boolean-crosses'
import inside from '@turf/inside'
import {point, lineString} from '@turf/helpers'
import kinks from '@turf/kinks'
import pointOnLine from '@turf/point-on-line'

import {geoJsonFormat} from '@helpers/openlayers/features'

import polygonSlice from './polygon-slice'
import {cutLine, cutPreview} from './styles'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  inject: [
    'getMap'
  ],
  components: {
    IxVectorLayer
  },
  props: {
    features: Array
  },
  data () {
    return {
      results: []
    }
  },
  computed: {
    resultFeatures () {
      const features = this.results
        .reduce((features, polygonResult) => features.concat(polygonResult.parts.features), [])

      features.forEach((feature, i) => {
        feature.properties = {
          index: i
        }
      })

      return features
    },
    vectorStyle () {
      return cutPreview
    }
  },
  methods: {
    init () {
      this.drawInteraction = new Draw({
        type: 'LineString',
        freehandCondition: () => false,
        style: cutLine
      })
      this.map.addInteraction(this.drawInteraction)

      this.listeners.push(this.drawInteraction.on('drawstart', this.onDrawStart))
      this.listeners.push(this.drawInteraction.on('drawend', this.onDrawEnd))

      document.addEventListener('keydown', this.onKeyDown)
    },
    onPreviewChange (event) {
      const line = {
        type: 'Feature',
        geometry: geoJsonFormat.writeGeometryObject(event.target)
      }
      this.applyCut(line)
    },
    onDrawStart (event) {
      this.previewChangeListener = event.feature.getGeometry().on('change', this.onPreviewChange)

      document.addEventListener('keydown', this.onKeyDown)
    },
    onDrawEnd () {
      unByKey(this.previewChangeListener)

      this.$emit('cut', this.results)
      this.results = []

      document.removeEventListener('keydown', this.onKeyDown)
    },
    onKeyDown (event) {
      if (event.keyCode === 27) { // esc
        try {
          this.drawInteraction.finishDrawing()
        } catch (error) {
          console.error(error)
        }
      }
    },
    applyCut (line) {
      const results = []
      this.features
        .filter(feature => feature.geometry.type === 'Polygon')
        .forEach(polygon => {
          const first = point(line.geometry.coordinates[0])
          const last = point(line.geometry.coordinates[line.geometry.coordinates.length - 1])
          const pointsOnLine = polygon.geometry.coordinates.map(x => ({
            first: pointOnLine(lineString(x), first, 'degrees').properties.dist,
            last: pointOnLine(lineString(x), last, 'degrees').properties.dist
          }))
          if (booleanCrosses(line, polygon) && !inside(first, polygon) && !inside(last, polygon) && !kinks(line).features.length && !pointsOnLine.some(x => x.first < 1E-6 || x.last < 1E-6)) {
            const result = polygonSlice(polygon, line)
            results.push({feature: polygon, parts: result.polygons})
          }
        })
      this.results = results
    }
  },
  created () {
    this.listeners = []

    this.getMap().then(map => {
      this.map = map
      this.init()
    })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeyDown)

    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction)
    }
    if (this.previewChangeListener) {
      unByKey(this.previewChangeListener)
    }

    this.listeners.forEach(key => {
      unByKey(key)
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
