<template lang="html">
  <div class="geometry-editing">
    <FrsExperimentalFeature>
      <h4>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.BorderRecognition</IxRes>
      </h4>
      <BorderRecognition />
    </FrsExperimentalFeature>
    <h4><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Geometry</IxRes></h4>
    <EditingTools />

    <CollapsibleSection v-model="showGuideTools">
      <template #header>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Construction</IxRes>
      </template>
      <EditingTools guides />
    </CollapsibleSection>

    <CollapsibleSection v-model="showAdditionalTools">
      <template #header>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AdditionalTools</IxRes>
      </template>
      <AdditionalTools />
    </CollapsibleSection>

    <CollapsibleSection v-model="showGeneralTools">
      <template #header>
        <IxRes>Common.SR_Common.GeneralFunctions</IxRes>
      </template>
      <GeneralEditingSettings />
    </CollapsibleSection>

    <IxVectorLayer
      v-if="!['edit'].includes(mode)"
      :features="activeFeatures"
      :vector-style="vectorStyle"
      layer-id="editing"
    />
    <IxVectorLayer
      v-if="!['editGuide'].includes(mode)"
      :features="guideFeatures"
      :vector-style="guidesStyle"
      layer-id="guides"
    />

    <IxWFSLayer
      v-if="roads"
      url="/api/v2/entities/fields/geodata/roads"
      :layer-style="roadStyle"
      :max-resolution="10"
    />

    <ExpertModeInteractions />

    <IxGeometryErrorLayer
      v-if="showGeometryErrors"
      :features="activeFeatures"
    />

    <!-- <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="fa-ul">
          <li>
            <i class="fa fa-info-circle fa-lg fa-li"></i><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.UsefulTipsForUsing</IxRes>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {roads, fieldBorderSuggestion} from '@frs/map-styles'

import {editing, guides} from '@components/map/interactions/styles'
import FrsExperimentalFeature from '@frs/components/base/FrsExperimentalFeature'
import CollapsibleSection from '@components/CollapsibleSection'
import IxVectorLayer from '@components/map/IxVectorLayer'
import IxWFSLayer from '@components/map/IxWFSLayer'
import IxGeometryErrorLayer from '@components/map/IxGeometryErrorLayer'

import EditingTools from './standard-editing/EditingTools'
import GeneralEditingSettings from './standard-editing/GeneralEditingSettings'
import AdditionalTools from './standard-editing/AdditionalTools'
import BorderRecognition from './standard-editing/BorderRecognition'
import ExpertModeInteractions from './expert-mode-editing/ExpertModeInteractions'

export default {
  components: {
    FrsExperimentalFeature,
    EditingTools,
    GeneralEditingSettings,
    AdditionalTools,
    BorderRecognition,
    CollapsibleSection,
    IxVectorLayer,
    IxWFSLayer,
    ExpertModeInteractions,
    IxGeometryErrorLayer
  },
  data () {
    return {
      showGuideTools: false,
      showAdditionalTools: false,
      showGeneralTools: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      activeFeatures: state => state.editing.activeFeatures,
      guideFeatures: state => state.editing.guideFeatures,
      mode: state => state.editing.mode,
      roads: state => state.editing.roads,
      showGeometryErrors: state => state.editing.showGeometryErrors
    }),
    roadStyle () {
      return roads
    },
    borderStyle () {
      return fieldBorderSuggestion
    },
    vectorStyle () {
      return editing
    },
    guidesStyle () {
      return guides
    }
  }
}
</script>

<style lang="scss" scoped>
.geometry-editing {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

</style>
