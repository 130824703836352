<template lang="html">
  <div class="wrapper">
    <h3 class="group-header">
      <slot></slot>
    </h3>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding-bottom: 4px;
}

.group-header {
  padding: 3px;
  // position: sticky;
  // top: -5px;
  text-overflow: ellipsis;
  border: 1px solid #bbb;
  background-color: white;
}

h3 {
  margin: 0;
}
</style>
