import booleanContains from '@turf/boolean-contains'
import lineSplit from '@turf/line-split'
import lineIntersect from '@turf/line-intersect'
import {getCoords} from '@turf/invariant'
import {polygon, lineString, featureCollection} from '@turf/helpers'
import rewind from '@turf/rewind'

// line split coordinates for line/poly and poly/line are not identical, approximate equality
const equal = (c1, c2) => Math.abs(c1[0] - c2[0]) < 1E-6 && Math.abs(c1[1] - c2[1]) < 1E-6
const strictlyEqual = (c1, c2) => c1[0] === c2[0] && c1[1] === c2[1]

const addDebugLabel = prefix => (x, i) => {
  if (!x.properties.label) {
    x.properties.label = `${prefix}${i}`
  }
}
const addDebugLabels = (collection, prefix) => {
  collection.features.forEach(addDebugLabel(prefix))
}

const mendRingSeam = (parts, seamCoordinate) => {
  const firstSegment = parts.features.find(x => strictlyEqual(x.geometry.coordinates[0], seamCoordinate))
  const lastSegment = parts.features.find(x => strictlyEqual(x.geometry.coordinates[x.geometry.coordinates.length - 1], seamCoordinate))
  lastSegment.geometry.coordinates.push(...firstSegment.geometry.coordinates.slice(1))
  parts.features.splice(parts.features.indexOf(firstSegment), 1)
  // parts.features[parts.features.length - 1].geometry.coordinates.push(...parts.features[0].geometry.coordinates.slice(1))
  // parts.features = parts.features.slice(1)
}

// line split coordinates for line/poly and poly/line are not identical, use cut line points instead of almost equal ring points
// const combinePartialRingWithCutLineSegment = (ringCoords, segmentCoords) =>
//   [segmentCoords[segmentCoords.length - 1]]
//     .concat(ringCoords.slice(1, -1))
//     .concat(segmentCoords)

export default (polygonToCut, line) => {
  polygonToCut = rewind(polygonToCut)
  const rings = getCoords(polygonToCut)
  const boundary = lineString(rings[0])
  const holes = featureCollection(rings.slice(1).map(x => lineString(x)))

  const intersectionPoints = lineIntersect(boundary, line)
  const boundaryParts = lineSplit(boundary, line)

  const holesToBeCut = featureCollection([])
  const remainingHoles = featureCollection([])
  const holeParts = featureCollection([])

  for (const hole of holes.features) {
    const splitHole = lineSplit(hole, line)
    if (splitHole.features.length) {
      mendRingSeam(splitHole, hole.geometry.coordinates[0])
      holesToBeCut.features.push(hole)
      holeParts.features.push(...splitHole.features)
    } else {
      remainingHoles.features.push(hole)
    }
  }

  // const holesToBeCut = featureCollection(splitHoles.filter(x => lineIntersect(x, line).features.length))
  // const remainingHoles = featureCollection(holes.features.filter(x => !lineIntersect(x, line).features.length))
  // const holeParts = featureCollection(
  //   holesToBeCut.features
  //     .map(x => {
  //       const parts = lineSplit(x, line)
  //       mendRingSeam(parts)
  //       return parts.features
  //     })
  //     .reduce((allParts, parts) => allParts.concat(parts), [])
  // )
  // // holeParts.features.forEach(x => x.geometry.coordinates.reverse())

  mendRingSeam(boundaryParts, boundary.geometry.coordinates[0])

  const cutLineParts = lineSplit(line, polygonToCut)
  cutLineParts.features = cutLineParts.features.filter(x => booleanContains(polygonToCut, x))

  let polygons = featureCollection([])

  if (cutLineParts.features.length && boundaryParts.features.length) {
    const addPartialPolygon = boundaryRingCoords => {
      const newPolygon = polygon([boundaryRingCoords])
      const containedHoles = remainingHoles.features.filter(x => booleanContains(newPolygon, x))
      if (containedHoles.length) {
        newPolygon.geometry.coordinates.push(...containedHoles.map(x => x.geometry.coordinates))
      }
      polygons.features.push(newPolygon)
    }

    const alreadyProcessedBoundaryParts = []

    boundaryParts.features.concat(holeParts.features).forEach(partialRing => {
      if (alreadyProcessedBoundaryParts.includes(partialRing)) {
        return
      }

      alreadyProcessedBoundaryParts.push(partialRing)

      const coordinates = []

      const startingSegment = getCoords(partialRing)

      let lastSegment = startingSegment
      let nextType = null

      const addRingSegment = segment => {
        coordinates.push(...segment.slice(1, -1))
        lastSegment = segment
        nextType = 'cutLine'
      }
      const addCutLineSegment = segment => {
        coordinates.push(...segment)
        lastSegment = segment
        nextType = 'boundary'
      }

      addRingSegment(startingSegment)

      let count = 0

      while (count++ < 100 && (!coordinates.length || !equal(startingSegment[0], coordinates[coordinates.length - 1]))) {
        switch (nextType) {
        case 'cutLine': {
          for (const candidate of cutLineParts.features) {
            const coords = getCoords(candidate)
            if (equal(lastSegment[lastSegment.length - 1], coords[0])) {
              addCutLineSegment(coords)
              break
            } else if (equal(lastSegment[lastSegment.length - 1], coords[coords.length - 1])) {
              // NOTE might be possible to just use it in-place as-is, will be potentially reversed twice in that case
              coords.reverse()
              addCutLineSegment(coords)
              break
            }
          }
          break
        }
        case 'boundary': {
          for (const candidate of boundaryParts.features.concat(holeParts.features)) {
            if (alreadyProcessedBoundaryParts.includes(candidate)) {
              continue
            }

            const coords = getCoords(candidate)
            if (equal(lastSegment[lastSegment.length - 1], coords[0])) {
              addRingSegment(coords)
              alreadyProcessedBoundaryParts.push(candidate)
              break
            }
          }
          break
        }
        }
      }

      if (coordinates.length) {
        coordinates.unshift(coordinates[coordinates.length - 1])
      }

      if (coordinates.length >= 4) {
        addPartialPolygon(coordinates)
      }
    })

    // featureEach(boundaryParts, (partialRing) => {
    //   const partialRingCoords =  getCoords(partialRing)
    //   const start = partialRingCoords[0]
    //   const end = partialRingCoords[partialRingCoords.length - 1]
    //
    //   featureEach(cutLineParts, (cutLineSegment) => {
    //     const cutLineSegmentCoords = getCoords(cutLineSegment)
    //     const cutLineStart = cutLineSegmentCoords[0]
    //     const cutLineEnd = cutLineSegmentCoords[cutLineSegmentCoords.length - 1]
    //
    //     if (equal(start, cutLineStart) && equal(end, cutLineEnd)) {
    //       // partialRing CW, cutLineSegment CCW, cutLineSegment coordinates need to be reversed
    //       cutLineSegmentCoords.reverse()
    //       addPartialPolygon(combinePartialRingWithCutLineSegment(partialRingCoords, cutLineSegmentCoords))
    //     } else if (equal(start, cutLineEnd) && equal(end, cutLineStart)) {
    //       // partialRing CW, cutLineSegment CW, coordinate order already correct
    //       addPartialPolygon(combinePartialRingWithCutLineSegment(partialRingCoords, cutLineSegmentCoords))
    //     }
    //   })
    // })
  } else {
    polygons.features.push(polygonToCut)
  }
  addDebugLabels(cutLineParts, 'c')
  addDebugLabels(intersectionPoints, 'i')
  addDebugLabels(boundaryParts, 'p')
  addDebugLabels(holes, 'h')
  addDebugLabels(holeParts, 'hp')
  addDebugLabels(holesToBeCut, 'hc')
  addDebugLabels(remainingHoles, 'rh')
  polygons.features.forEach(addDebugLabel('s'))
  polygons.features.forEach((f, i) => {
    f.properties.index = i
  })
  return {boundary: {features: [boundary]}, polygons, intersectionPoints, cutLineParts, boundaryParts, holes, holeParts, holesToBeCut, remainingHoles}
}
