<template lang="html">
  <div class="entity-selection">
    <HelpBox>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EntityImportEntitySelection_hint</IxRes>
    </HelpBox>
    <div class="entity-list">
      <EntityPreviewCard v-for="entity in tree" :key="entity.id" :entity="entity" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import HelpBox from '@components/help/HelpBox'

import EntityPreviewCard from './EntityPreviewCard'

export default {
  components: {
    HelpBox,
    EntityPreviewCard
  },
  computed: {
    ...mapGetters('fieldRecordSystem/entityImport', [
      'tree'
    ]),
    strippedTree () {
      const tree = JSON.parse(JSON.stringify(this.tree))

      const strip = nodes => nodes.forEach(x => {
        delete x.feature
        if (x.children) {
          strip(x.children)
        }
      })

      strip(tree)

      return tree
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-selection {
  padding: 5px 5px 0 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.entity-list {
  flex: 1;
  overflow-y: auto;
}
</style>
