<template lang="html">
  <div class="measurement-overlay">
    <slot />
  </div>
</template>

<script>
import OverlayMixin from '@components/map/mixins/OverlayMixin'

export default {
  mixins: [
    OverlayMixin
  ],
  data () {
    return {
      stopEvent: false
    }
  }
}
</script>

<style lang="scss" scoped>
.measurement-overlay {
  font-size: 1.5em;
  color: white;
}
</style>
