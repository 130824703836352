<template lang="html">
  <div class="row">
    <div class="col-md-6">
      <button
        type="button" class="btn btn-default action-button btn-block"
        style="margin-top:5px;"
        :disabled="!selectedFeatures.length" :title="!selectedFeatures.length ? SR_FieldRecordSystem.SelectGeometryToCopyIt : SR_FieldRecordSystem.CopySelectedGeometryToClipboard"
        @click.prevent="copy"
      >
        <i class="fa fa-copy" />
        <IxRes>Common.SR_Common.Copy</IxRes>
      </button>
      <button class="btn btn-default btn-block" :disabled="!selectedFeatures.length" @click="onDeleteSelection">
        <i class="fa fa-trash" aria-hidden="true" :title="!selectedFeatures.length ? SR_FieldRecordSystem.SelectGeometryFirstToDeleteIt : SR_FieldRecordSystem.DeleteSelectedGeometry" />
        <IxRes>Common.SR_Common.Delete</IxRes>
      </button>
      <ToggleButton
        v-model="mode" value="measureDistance"
        class="btn-block action-button"
        required
      >
        <FontAwesomeIcon :icon="measureDistanceIcon" />
        <IxRes>Common.SR_Common.MeasureDistance</IxRes>
      </ToggleButton>
    </div>
    <div class="col-md-6">
      <ToggleButton
        v-model="mode" value="measureArea"
        class="btn-block action-button" style="margin-top:5px;"
        required
      >
        <FontAwesomeIcon :icon="measureAreaIcon" />
        <IxRes>Common.SR_Common.MeasureArea</IxRes>
      </ToggleButton>
      <button
        type="button" class="btn btn-default action-button btn-block"
        style="margin-top:5px;"
        :disabled="selectedFeatures.length === 0" @click.prevent="onSimplifyGeometry"
      >
        <IxRes>Common.SR_Common.SimplifyGeometry</IxRes>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import ToggleButton from '@components/ToggleButton'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faRuler, faRulerCombined, faBurn} from '@fortawesome/free-solid-svg-icons'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  inject: [
    'getMap'
  ],
  components: {
    ToggleButton,
    FontAwesomeIcon
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.mode'
    ]),
    ...mapGetters('fieldRecordSystem/map', [
      'selectedFeatures'
    ]),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    measureDistanceIcon () {
      return faRuler
    },
    measureAreaIcon () {
      return faRulerCombined
    },
    burnIcon () {
      return faBurn
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/map', [
      'recordFeatures',
      'copyToClipboard'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'simplifyGeometry',
      'deleteSelection'
    ]),
    onSimplifyGeometry () {
      this.recordFeatures()
      this.simplifyGeometry()
    },
    copy () {
      this.copyToClipboard(this.selectedFeatures)
    },
    onDeleteSelection () {
      this.deleteSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
