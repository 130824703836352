<template>
  <DynamicScroller
    :items="items"
    :min-item-size="38"
    class="field-list"
  >
    <template #default="{item, index, active}">
      <DynamicScrollerItem
        :item="item"
        :active="active"
        :size-dependencies="[
          item.showDetails
        ]"
        :data-index="index"
      >
        <GroupedFieldHeader v-if="item.type === 'header'">
          <slot v-bind="{key: item.key}" name="groupHeader">
            {{ item.key }}
          </slot>
        </GroupedFieldHeader>
        <GroupedFieldItem
          v-else
          :item="item"
          :selected="(mixedSelectionFilter && mixedSelectionFilter(item.field.id)) ? null : selectedFieldIds.includes(item.field.id)"
          show-tags
          no-menu
          @click.native="$emit('click', item.field.id)"
        >
          <template v-if="$scopedSlots.avatar" #avatar>
            <slot name="avatar" :field-id="item.field.id"></slot>
          </template>
          <template v-if="$scopedSlots.header" #header>
            <slot name="header" :field="item.field"></slot>
          </template>
          <template #default>
            <slot :field="item.field"></slot>
          </template>
          <template v-if="$scopedSlots.subtitle" #subtitle>
            <slot name="subtitle" :field="item.field"></slot>
          </template>
        </GroupedFieldItem>
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import {DynamicScroller, DynamicScrollerItem} from 'vue-virtual-scroller'
import GroupedFieldHeader from './GroupedFieldHeader.vue'
import GroupedFieldItem from './GroupedFieldItem.vue'

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    GroupedFieldHeader,
    GroupedFieldItem
  },
  props: {
    groups: Array,
    selectedFieldIds: Array,
    mixedSelectionFilter: Function,
    showDetails: Boolean
  },
  computed: {
    items () {
      return this.groups.filter(x => x.fields.length)
        .reduce((all, group) => {
          const {key} = group

          all.push({id: key, type: 'header', key})
          all.push(...group.fields.map(field => ({
            id: field.id, 
            type: 'field', 
            key, 
            field,
            showDetails: this.showDetails
          })))

          return all
        }, [])
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
