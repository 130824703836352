<template lang="html">
  <div class="left-sidebar">
    <PortalTarget v-if="$route.meta.leftSidebar" name="leftSidebar" class="left-sidebar-portal-target" />
    <WorkflowLeft v-else-if="!editing.active && workflow && (workflow.left || workflow.usePortals)" />
    <div v-else-if="editing.active && editing.flavor === 'expert'" class="sidebar-editing">
      <StandardEditing />
    </div>
    <div v-else class="sidebar-navigation">
      <NavigationHeader />

      <div class="nav-component">
        <component :is="navigationComponent" />
      </div>

      <div v-if="$can('Access.Frs.RestructuringToolbar')" class="button-container">
        <IxButton disabled class="flex-grow" large>
          <i class="fa fa-lock" /><ix-res>Areas.FieldRecordSystem.SR_FieldRecordSystem.ManageModules</ix-res>
        </IxButton>
        <IxButton
          v-if="$can('Use.Frs.Restructuring')"
          class="flex-grow"
          large
          @click="showStructureModal"
        >
          <i class="fa fa-sitemap" />
          <ix-res>Areas.FieldRecordSystem.SR_FieldRecordSystem.CustomizeStructure</ix-res>
        </IxButton>
      </div>
    </div>

    <RelocationModal />
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import WorkflowLeft from '@frs/components/WorkflowLeft'
import NavigationHeader from '@frs/components/navigation/NavigationHeader'
import TreeNav from '@frs/components/navigation/tree/TreeNav'
import FrsRocket from '@frs/components/navigation/FrsRocket'
import TagNav from '@frs/components/navigation/tags/TagNav'
import SearchResults from '@frs/components/navigation/search/SearchResults'
import StandardEditing from '@frs/components/geometry-editing/StandardEditing'
import IxButton from '@components/IxButton'
import RelocationModal from '@frs/components/orgunit/RelocationModal'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    WorkflowLeft,
    NavigationHeader,
    StandardEditing,
    IxButton,
    RelocationModal
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    expanded: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      searchText: state => state.navigation.search.text,
      view: state => state.ui.view.left,
      workflow: state => state.ui.workflow,
      editing: state => state.map.editing
    }),
    ...mapFormFields('fieldRecordSystem', [
      'ui.mainPageLoading'
    ]),
    navigationComponent () {
      if (this.searchText) {
        return SearchResults
      }
      switch (this.view) {
      case 'legacy': return TreeNav
      case 'rocket': return FrsRocket
      case 'groups': return TagNav
      default: return FrsRocket
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setLeftView'
    ]),
    async showStructureModal () {
      this.mainPageLoading = true
      await new Promise(resolve => setTimeout(resolve, 0))
      this.$store.commit('fieldRecordSystem/setEntityStructureEditing', true)
    },
    reactToRouteChange (to) {
      if (to.name.startsWith('tag')) {
        this.setLeftView('groups')
      } else {
        this.setLeftView('rocket')
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.reactToRouteChange(to)
    })
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.reactToRouteChange(to)
  }
}
</script>

<style lang="scss" scoped>
.left-sidebar, .sidebar-editing, .sidebar-navigation, .left-sidebar-portal-target {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar-editing, .sidebar-navigation {
  padding: 5px;
}
.nav-component {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; // necessary for proper overflow handling of children, specifically for Firefox
  overflow-y: auto;
}
.button-container {
  display: flex;
}
.flex-grow {
  flex: 1;
  margin: .5em;
}
</style>
