<template lang="html">
  <ListCard :title="group" :selected="selected" class="sticky">
    <div slot="header" class="size">
      <span class="field-count">{{ fieldCount }}</span>
    </div>
  </ListCard>
</template>

<script>
import ListCard from '@components/card/ListCard'

export default {
  components: {
    ListCard
  },
  props: {
    group: String,
    fieldCount: Number,
    selected: Boolean
  }
}
</script>

<style lang="scss" scoped>
.field-count {
  color: #DDD;
  margin-left: 0.25em;
  font-size: 2em;
  line-height: 0.9em;
  font-weight: 100;
}
.sticky {
  top: 0;
  position: sticky;
}
</style>
