<script>
import {mapState, mapGetters} from 'vuex'
import {sortBy} from 'lodash'

import {mapResources, modelProxy} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'
import {hoverStyle, selectionStyle} from '@frs/map-styles'

import IxRes from '@components/IxRes'
import SimpleSwitch from '@components/forms/SimpleSwitch'
import SelectInteraction from '@components/map/interactions/SelectInteraction'
import FrsCultivationOverviewTable from '@frs/components/base/FrsCultivationOverviewTable'

import SortModeSelector from './FrsFieldSelection/SortModeSelector'
import GroupedFieldList from './FrsFieldSelection/GroupedFieldList'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  mixins: [
    DisableDefaultMapInteractionsMixin
  ],
  props: {
    value: Array, // of field ids
    mixedSelectionFilter: Function,
    filter: {
      type: Function,
      default: () => () => true
    },
    noMapInteraction: Boolean
  },
  data () {
    return {
      groupMode: 'orgUnit',
      showDetails: false
    }
  },
  computed: {
    modelProxy,
    ...mapResources([
      'Messages.State.SR_StateMessages'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'entityNameLookup',
      'fieldsForCurrentOrgUnit',
      'fieldIdsForCurrentOrgUnitByTag',
      'fieldFeaturesForCurrentOrgUnit'
    ]),
    ...mapGetters('fieldRecordSystem/navigation', [
      'parentLookup'
    ]),
    // This dependency is terrible since it requires all modules that want to use grouping to call reloadCultivationsForOrgUnit on module load
    ...mapGetters('fieldRecordSystem/cultivationPlanning', [
      'fieldIdsByCultivationType',
      'cultivationsByFieldId',
      'fieldIdsByCropId'
    ]),
    ...mapState('fieldRecordSystem', {
      workflow: state => state.ui.workflow,
      crops: state => state.data.crops
    }),
    ...mapState('fieldRecordSystem/navigation', {
      simplifiedGeometries: state => state.simplifiedGeometries
    }),
    ...mapState('fieldRecordSystem/map', {
      features: state => state.layers.fields.features
    }),
    sortedFields () {
      return sortBy(this.fieldsForCurrentOrgUnit.filter(this.filter), 'name')
    },
    fieldLookup () {
      const lookup = {}
      for (const field of this.fieldsForCurrentOrgUnit) {
        lookup[field.id] = field
      }
      return lookup
    },
    fieldIdsByParentId () {
      const lookup = {}

      for (const field of this.sortedFields) {
        const parentId = this.parentLookup[field.id]

        if (!(parentId in lookup)) {
          lookup[parentId] = []
        }
        lookup[parentId].push(field.id)
      }

      return lookup
    },
    fieldIdLookups () {
      return {
        orgUnit: this.fieldIdsByParentId,
        cultivationType: this.fieldIdsByCultivationType,
        tags: this.fieldIdsForCurrentOrgUnitByTag,
        crop: this.fieldIdsByCropId
      }
    },
    fieldIdLookup () {
      return this.fieldIdLookups[this.groupMode]
    },
    groupedFields () {
      const getField = id => this.fieldLookup[id]

      const seenFields = {}
      const groupedFields = []

      const keys = Object.keys(this.fieldIdLookup)

      if (this.groupMode !== 'cultivationType') {
        keys.sort()
      }

      for (const key of keys) {
        const fieldIds = this.fieldIdLookup[key]

        for (const fieldId of fieldIds) {
          seenFields[fieldId] = true
        }

        groupedFields.push({
          key,
          fields: sortBy(fieldIds.map(getField).filter(this.filter), 'name')
        })
      }

      const unseenFields = this.sortedFields.filter(this.filter).filter(field => !seenFields[field.id])

      return unseenFields.length
        ? groupedFields.concat({
          key: null,
          fields: unseenFields
        })
        : groupedFields
    },
    unlistedFieldCount () {
      return this.sortedFields.length - this.groupedFields.reduce((sum, group) => sum + group.fields.length, 0)
    }
  },
  methods: {
    toggleField (fieldId) {
      if (this.fieldHasWkt(fieldId)) {
        const fieldIds = this.value.includes(fieldId)
          ? this.value.filter(x => x !== fieldId)
          : this.value.concat([fieldId])

        this.$emit('input', fieldIds)
        this.$emit('toggle', fieldId)
      } else {
        notifications.error(this.SR_StateMessages.NoWkt)
      }
    },
    fieldHasWkt (fieldId) {
      return this.simplifiedGeometries[fieldId]
    }
  },
  render (h) {
    const defaultGroupTranslationKeys = {
      cultivationType: 'frs.navigation.groupHeaders.noCultivationType',
      tags: 'frs.navigation.groupHeaders.noTag',
      crop: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationWithoutCrop'
    }

    const GroupHeader = ({key}) => {
      if (!key) return h(IxRes, {}, defaultGroupTranslationKeys[this.groupMode])

      switch (this.groupMode) {
      case 'orgUnit': return this.entityNameLookup[key]
      case 'cultivationType': return h(IxRes, {}, `Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivationType_${key}`)
      case 'tags': return key
      case 'crop': return h(IxRes, {}, `masterData.crops.usage.${key}_name`)
      }
    }

    return h('div', {class: 'field-selection'}, [
      h(SortModeSelector, {
        props: {value: this.groupMode},
        on: {
          input: value => {
            this.groupMode = value
          }
        }
      }),
      h('div', {class: 'show-details-switch'}, [
        h(SimpleSwitch, {
          props: {value: this.showDetails},
          on: {
            input: value => {
              this.showDetails = value
            }
          }
        }, [this.$slots['details-label'] || h(IxRes, 'Areas.FieldRecordSystem.SR_FieldRecordSystem.DisplayCultivationPlans')])
      ]),
      h(GroupedFieldList, {
        props: {
          groups: this.groupedFields,
          showDetails: this.showDetails,
          selectedFieldIds: this.value,
          mixedSelectionFilter: this.mixedSelectionFilter
        },
        on: {click: this.toggleField},
        scopedSlots: {
          groupHeader: GroupHeader,
          avatar: this.$scopedSlots.avatar ? ({fieldId}) => this.$scopedSlots.avatar({fieldId}) : undefined,
          default: this.$scopedSlots.default || (scope => h(FrsCultivationOverviewTable, {props: {cultivations: sortBy(this.cultivationsByFieldId[scope.field.id], 'dateTimeRange.start')}})),
          subtitle: this.$scopedSlots.subtitle,
          header: this.$scopedSlots.header
        }
      }),
      this.noMapInteraction ? null : h(SelectInteraction, {
        props: {
          value: this.modelProxy,
          features: this.fieldFeaturesForCurrentOrgUnit,
          selectionStyle: selectionStyle,
          hoverStyle: hoverStyle,
          layerFilter: layer => layer.get('id') === 'fields',
          idKey: 'id',
          multiple: true
        },
        on: {
          input: value => {
            this.modelProxy = value
          }
        }
      })
    ])
  }
}
</script>

<style lang="scss" scoped>
.show-details-switch {
  padding: 5px;
}
.field-selection {
  height: 100%;
  display: flex;
  flex-direction: column;

  .btn-group {
    margin: 5px;
  }
}
.field-list {
  padding: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
.sticky {
  position: sticky;
  top: 0;
}
</style>
