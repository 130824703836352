<template lang="html">
  <FrsFieldSelection
    v-model="selectedFieldIds"
    :filter="field => fieldData[field.id].wkt"
    @toggle="loadNextCropPredictionForField"
  />
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'

export default {
  components: {
    FrsFieldSelection
  },
  computed: {
    ...mapState('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'fieldIds'
    ]),
    ...mapFormFields('fieldRecordSystem/cultivationPlanning/editBuffer', [
      'fieldIds',
      'geometry'
    ]),
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    selectedFieldIds: {
      get () {
        return this.fieldIds
      },
      set (fieldIds) {
        this.fieldIds = fieldIds
        const wkts = fieldIds.map(id => this.fieldData[id].wkt)
        this.geometry = wkts
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/cultivationPlanning', [
      'loadNextCropPredictionForField'
    ])
  },
  updated () {
    this.$nextTick(function () {
      const selectedItems = document.querySelector('.list-card.selected')
      if (selectedItems) {
        selectedItems.scrollIntoViewIfNeeded()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
