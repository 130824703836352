<template lang="html">
  <FrsFieldSelection
    :value="selectedFieldIds"
    :filter="({id}) => fieldIds.includes(id)"
    no-map-interaction
    @toggle="onToggle"
  >
    <template #subtitle>
      <IxRes>bf.orderPlanning.hint.pleaseSelectToPlan.{{ probingSiteMode }}</IxRes>
    </template>
    <template #header="{field}">
      <div class="active-probing-site-count" :class="{ok: activeCountByFieldId[field.id], 'has-duplicates': duplicateLabelsByFieldId[field.id].length}">
        <span class="number">{{ activeCountByFieldId[field.id] || 0 }}</span>
        <div>
          <span class="seperate-text-top">
            <IxRes>bf.orderPlanning.fieldSelection.active</IxRes>
          </span>
          <span class="seperate-text-bottom">
            <IxRes>bf.orderPlanning.fieldSelection.{{ probingSiteMode }}</IxRes>
          </span>
        </div>
      </div>
    </template>
  </FrsFieldSelection>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {sortBy} from 'lodash'

import {mapFormFields} from '@helpers/vuex'

import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'

export default {
  components: {
    FrsFieldSelection
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/basicFertilization/orderPlanning', [
      'selectedFieldId'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization/orderPlanning', [
      'pointsByFieldId',
      'probingSiteMode'
    ]),
    ...mapGetters('fieldRecordSystem/basicFertilization/orderPlanning', [
      'selectedOrder',
      'safeProbingSitesByFieldId',
      'activeCountByFieldId',
      'duplicateLabelsByFieldId'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'fieldsForCurrentOrgUnit'
    ]),
    fieldIds () {
      return this.selectedOrder ? this.selectedOrder.selectedFieldIds : []
    },
    selectedFieldIds () {
      return this.selectedFieldId ? [this.selectedFieldId] : []
    },
    fieldsForOrder () {
      if (!this.selectedOrder) return null
      return this.selectedOrder.selectedFieldIds.map(id => this.fieldsForCurrentOrgUnit.find(field => field.id === id))
    }
  },
  methods: {
    onToggle (id) {
      if (id !== this.selectedFieldId) {
        this.selectedFieldId = id
      }
    }
  },
  watch: {
    selectedOrder: {
      immediate: true,
      handler () {
        if (this.selectedOrder) {
          this.onToggle(sortBy(this.fieldsForOrder, 'name')[0].id)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active-probing-site-count {
  color: #DDD;
  margin-left: 0.25em;
  display: flex;

  &.ok {
    color: green;
  }

  &.has-duplicates {
    color: red;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .number {
    font-size: 3.5em;
    line-height: 0.9em;
    font-weight: 100;
    margin-right: 0.1em;
  }

  .seperate-text-top {
    font-size: 1.5em;
    font-weight: 100;
  }

  .seperate-text-bottom {
    font-size: 1.5em;
    margin-top: -0.6em;
    font-weight: 100;
    align-self: flex-end;
  }
}
</style>
