<template lang="html">
  <div class="bf-field-selection">
    <FrsFieldSelection :value="[selectedFieldId]" :filter="({id}) => samplingYearsByFieldId[id] !== '1'" @toggle="onToggle">
      <template #subtitle="{field}">
        <span v-if="samplingYearsByFieldId[field.id] === '1'" class="subtitle" title="year">
          <IxRes>bf.fieldItem.noProbingDate</IxRes>
        </span>
        <span v-else class="subtitle" title="year">
          <IxRes>bf.fieldItem.lastProbingDate</IxRes>:
          {{ samplingYearsByFieldId[field.id] }}
        </span>
      </template>
    </FrsFieldSelection>

    <FrsLoadingIndicator :requests="['bf.field.statistics']" />
    <DisabledOverlay v-if="step !== 'basicSettings'" />
  </div>
</template>

<script>
import moment from 'moment'
import {mapState, mapActions} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import DisabledOverlay from '@components/DisabledOverlay'
import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsFieldSelection,
    FrsLoadingIndicator,
    DisabledOverlay
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/basicFertilization/applicationMaps', [
      'selectedFieldId',
      'step'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization', {
      fieldStatisticsLookup: state => state.data.fieldStatisticsLookup.statistics,
      simplifiedGeometries: state => state.data.simplifiedGeometries
    }),
    samplingYearsByFieldId () {
      const lookup = {}

      for (const fieldId in this.fieldStatisticsLookup) {
        lookup[fieldId] = moment(this.fieldStatisticsLookup[fieldId].lastSamplingDate).year().toString()
      }
      return lookup
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    onToggle (id) {
      this.selectedFieldId = id
    }
  },
  watch: {
    selectedFieldId (id) {
      if (!id) return

      this.focus(this.simplifiedGeometries[id])
    }
  }
}
</script>

<style lang="scss" scoped>
.bf-field-selection {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
