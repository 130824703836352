<template lang="html">
  <div class="import-button">
    <button class="btn btn-default btn-block" @click="showUploadModal = true">
      <i
        class="fa fa-upload" aria-hidden="true"
        required
        disabled
      />
      <IxRes>Common.SR_Common.ImportLong</IxRes>
    </button>
    <GeometryImportModal
      v-model="showUploadModal"
      @load="addFeatures"
    >
      <template #title>
        <IxRes>Common.SR_Common.ImportGeometry</IxRes>
      </template>
    </GeometryImportModal>
  </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {geoJsonFormat, dissolveMultiGeometries} from '@helpers/openlayers/features'

import GeometryImportModal from '@components/forms/GeometryImportModal'

let count = 0

export default {
  components: {
    GeometryImportModal
  },
  props: {
    layer: String
  },
  data () {
    return {
      showUploadModal: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.activeFeatures',
      'editing.guideFeatures'
    ])
  },
  methods: {
    ...mapMutations('fieldRecordSystem/map', [
      'recordFeatures',
      'setActiveFeatures',
      'setSelectedIds',
      'setShowImportSimplificationHint'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'simplifyGeometry',
      'focus'
    ]),
    addFeatures (geojson) {
      const features = dissolveMultiGeometries(geoJsonFormat.readFeatures(geojson))
        .map(feature => geoJsonFormat.writeFeatureObject(feature))

      features.forEach(feature => {
        feature.properties.id = `imported_${count++}`
      })

      this.recordFeatures()
      this.showUploadModal = false

      if (this.layer === 'guides') {
        this.guideFeatures = this.guideFeatures.concat(features)
      } else {
        this.activeFeatures = this.activeFeatures.concat(features)
        this.$nextTick().then(() => {
          this.setSelectedIds(features.map(x => x.properties.id))

          this.simplifyGeometry()

          this.setShowImportSimplificationHint(true)
          this.focus(this.activeFeatures)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
button + .import-button {
  margin-top: 5px;
}
</style>
