<template lang="html">
  <div style="display: flex; flex-direction: column; ">
    <h4 class="group-hint">
      <small>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.GroupDisplayIndependentDescription</IxRes>
      </small>
    </h4>
    <div>
      <button
        style="margin-top: 5px;" type="button"
        class="btn"
        @click.prevent="showModal = !showModal"
      >
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AssignAreaGroups</IxRes>
      </button>
    </div>
    <div class="tag-groups">
      <div class="field-list">
        <GroupDisplay :groups="fieldsByTag" />
      </div>

      <BsModal v-model="showModal">
        <template #title>
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AssignAreaGroups</IxRes>
        </template>
        <div style="max-height: 80vh; overflow-y: auto">
          <TagAssignment @cancel="showModal = false" @save="save" />
        </div>
      </BsModal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {sortBy, mapValues} from 'lodash'

import BsModal from '@components/bootstrap/BsModal'

import GroupDisplay from './GroupDisplay'
import TagAssignment from './TagAssignment'

export default {
  components: {
    GroupDisplay,
    BsModal,
    TagAssignment
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'entityLookup',
      'allFieldIdsByTag'
    ]),
    fieldsByTag () {
      return mapValues(this.allFieldIdsByTag, ids => sortBy(ids.map(id => this.entityLookup[id]), 'name'))
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'setFieldTags'
    ]),
    save (tagsByField) {
      this.showModal = false
      for (const id in tagsByField) {
        this.setFieldTags({id, tags: tagsByField[id]})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-groups {
  display: flex;
  flex-direction: column;
}
.field-list {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
button {
  margin-bottom: 5px;
}
</style>
