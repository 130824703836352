<template lang="html">
  <div class="search-bar">
    <Search
      v-model="searchText" :data="nonCircularEntities"
      :keys="searchKeys"
      :threshold="0.3" @result="searchResult"
    />
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import Search from '@components/search/Search'

export default {
  components: {
    Search
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', [
      'userSettings'
    ]),
    ...mapFormFields('fieldRecordSystem/navigation', [
      'searching'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'allEntities'
    ]),
    showFieldCodes () {
      return !!this.userSettings.ui['dashboard.showFieldCodes']
    },
    searchKeys () {
      return this.showFieldCodes ? ['name', 'code'] : ['name']
    },
    nonCircularEntities () {
      return this.stripRecursion(this.allEntities)
    },
    searchText: {
      get () {
        return this.$store.state.fieldRecordSystem.navigation.search.text
      },
      set (text) {
        this.setSearchText(text)
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/navigation', [
      'setSearchText',
      'setSearchResult',
      'setSearching'
    ]),
    async searchResult (value) {
      this.setSearching(true)

      // nextTick() doesn´t work here
      await new Promise(resolve => setTimeout(resolve, 0))

      this.setSearchResult(value)

      this.setSearching(false)
    },
    stripRecursion (entities) {
      return entities.map(({id, name, type, code, children}) => ({
        id,
        name,
        type,
        code,
        children: children ? this.stripRecursion(children) : undefined
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  flex: 1;
}
</style>
