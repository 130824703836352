<template>
  <SimpleTable class="cultivation-table" :columns="cultivationColumns" :data="cultivations">
    <template #type="{row}">
      {{ cultivationTypeTranslations[row.type] }}
    </template>
    <template #cropUsageId="{row}">
      <span class="cultivation-item">
        <IxRes v-if="row.cropUsageId && row.cropUsageId !== '00000000-0000-0000-0000-000000000000'">masterData.crops.usage.{{ row.cropUsageId }}_name</IxRes>
        <IxRes v-if="row.underseedMixtureId" :context="{mixture: mixtures[row.underseedMixtureId].name}">frs.cultivation.underseedMixture</IxRes>
      </span>
    </template>
    <template #dateTimeRange="{value: {start, end}}">
      {{ $i18n.format(start, 'date') }} - {{ $i18n.format(end, 'date') }}
    </template>
  </SimpleTable>
</template>

<script>
import {mapState} from 'vuex'
import {mapResources} from '@helpers/vuex'

import SimpleTable from '@components/table/SimpleTable'

export default {
  components: {
    SimpleTable
  },
  props: {
    cultivations: Array
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      mixtures: state => state.catchCropMixtures.data.selection.orgUnit
    }),
    cultivationColumns () {
      return {
        type: 'Typ',
        cropUsageId: 'Anbau',
        dateTimeRange: 'Zeitraum'
      }
    },
    cultivationTypeTranslations () {
      return {
        main: this.SR_FieldRecordSystem.MainCrop,
        eco: this.SR_FieldRecordSystem.EcologicalPriorityArea,
        catch: this.SR_FieldRecordSystem.CatchCrop
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cultivation-table {
  margin-top: 5px;

  .cultivation-item {
    display: flex;
    flex-direction: column;
  }
}
.table {
  margin-bottom: 0 !important;
}
</style>
