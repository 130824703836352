<template lang="html">
  <div class="navigation-header">
    <HarvestYearSelector :disabled="!canChangeHarvestYear" />

    <div class="header-row">
      <FrsSearch @searching="setSearchingFlag" />
      <div class="btn-group navigation-modes">
        <ToggleButton
          v-model="view" :value="'rocket'"
          :title="SR_FieldRecordSystem.HierarchicalNavigation"
          required
        >
          <i class="fa fa-list" />
        </ToggleButton>
        <ToggleButton
          v-model="view" :value="'groups'"
          :title="SR_FieldRecordSystem.FieldGroups"
          required
        >
          <i class="fa fa-tags" />
        </ToggleButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

import {mapResources} from '@helpers/vuex'

import ToggleButton from '@components/ToggleButton'

import FrsSearch from './FrsSearch'
import HarvestYearSelector from './HarvestYearSelector'

export default {
  components: {
    FrsSearch,
    HarvestYearSelector,
    ToggleButton
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      leftView: state => state.ui.view.left,
      rightView: state => state.ui.view.right
    }),
    view: {
      get () {
        return this.leftView
      },
      set (view) {
        switch (view) {
        case 'rocket': {
          this.$router.push({name: 'home'})
          break
        }
        case 'legacy': {
          break
        }
        case 'groups': {
          this.$router.push({name: 'tagNavigationLandingPage'})
        }
        }
      }
    },
    canChangeHarvestYear () {
      return (this.rightView === 'default' && ['home', 'orgDashboard', 'fieldDashboard', 'mapManagementOrg', 'mapManagementField'].includes(this.$route.name))
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setLeftView'
    ]),
    ...mapMutations('fieldRecordSystem/navigation', [
      'setSearching'
    ]),
    setSearchingFlag (value) {
      this.setSearching(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-header {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}
.header-row {
  display: flex;
}
.navigation-modes {
  display: flex;
  margin-left: 5px;
}
</style>
