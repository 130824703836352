<template lang="html">
  <div class="tree-nav">
    <Tree v-model="orgUnit" class="fullheight" :nodes="nodes" />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import {convertOrgUnitNode} from '@frs/helpers/tree'

import Tree from '@components/nav/Tree'

export default {
  components: {
    Tree
  },
  computed: {
    ...mapState('fieldRecordSystem/navigation', {
      entityForest: state => state.entityForest
    }),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId'
    ]),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    orgUnit: {
      get () {
        return this.entityLookup[this.orgUnitId]
      },
      set (orgUnit) {
        this.$router.push({name: 'orgDashboard', params: {orgUnitId: orgUnit.id}})
      }
    },
    nodes () {
      return this.entityForest ? this.entityForest.map(convertOrgUnitNode) : []
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-nav {
  padding-top: 10px;
  overflow-y: auto;
}
</style>
