import {sortBy} from 'lodash'

export const convertOrgUnitNode = orgUnit => {
  return {
    text: orgUnit.name,
    icon: {
      iconName: 'ix-tree-orgunit',
      style: '',
      tooltip: 'Organisationseinheit'
    },
    isExpanded: false,
    extraIcons: [],
    liClass: '',
    isActive: false,
    data: {
      template: 'OrgUnit',
      uid: ''
    },
    href: '#',
    id: orgUnit.id,
    children: orgUnit.children.filter(x => x.children).map(convertOrgUnitNode),
    entity: orgUnit
  }
}

const convertNodeNonCircular = (entity) => {
  const {id, name, type, visibleOnly} = entity

  return {
    text: name,
    icon: {
      iconName: type === 'org' ? 'ix-tree-orgunit' : 'ix-tree-parcel',
      style: '',
      tooltip: type === 'org' ? 'Organisationseinheit' : 'Feld'
    },
    isExpanded: false,
    extraIcons: [],
    liClass: '',
    isActive: false,
    // data: {
    //   template: entity.type,
    //   uid: ''
    // },
    href: '#',
    id,
    children: entity.children ? sortBy(entity.children.map(convertNodeNonCircular), [item => item.text.toLowerCase()]) : null,
    entity: {
      id,
      name,
      type,
      visibleOnly
    }
  }
}

export const makeTreeNonCircular = entities => sortBy(entities.map(convertNodeNonCircular), [item => item.text.toLowerCase()])
