<template lang="html">
  <div class="wrapper">
    <FieldItem
      :show-details="item.showDetails"
      :field="item.field"
      :selected="selected"
      show-tags
      no-menu
    >
      <template v-if="$scopedSlots.avatar" #avatar>
        <slot name="avatar" :field-id="item.field.id"></slot>
      </template>
      <template v-if="$scopedSlots.header" #header>
        <slot name="header" :field="item.field"></slot>
      </template>
      <template #default>
        <slot :field="item.field"></slot>
      </template>
      <template v-if="$scopedSlots.subtitle" #subtitle>
        <slot name="subtitle" :field="item.field"></slot>
      </template>
    </FieldItem>
  </div>
</template>

<script>
import FieldItem from '@frs/components/navigation/items/FieldItem'

export default {
  components: {
    FieldItem
  },
  props: {
    showDetails: Boolean,
    selected: Boolean,
    item: Object
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding-bottom: 4px;
}
</style>
