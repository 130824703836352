<template lang="html">
  <ListCard
    class="list-card-container"
    :selected="selected"
    :show-details="showDetails"
    show-avatar
  >
    <template #avatar>
      <slot name="avatar" :field-id="field.id">
        <FieldBorderAvatar slot="avatar" :field-id="field.id" :loading="loading" />
      </slot>
    </template>
    <template #title>
      <span v-if="showCode && (info.code || field.code)" class="code label label-default">{{ fieldCode }}</span>
      <span class="name" :title="field.name">
        {{ field.name }}
      </span>
    </template>
    <template #subtitle>
      <slot name="subtitle">
        <a class="previous" href="#" @click.stop.prevent="toCultivationPlan">
          <template v-if="info && info.cultivationInfo">
            <IxRes v-if="info.cultivationInfo.count === 0">frs.fieldItem.infos.cultivationInfo.noCultivations</IxRes>
            <template v-if="info.cultivationInfo.count > 0">{{ toCultivationShortcut(info.cultivationInfo) }} {{ toCultivationName(info.cultivationInfo) }}</template>
            <template v-if="info.cultivationInfo.count > 1"> + {{ info.cultivationInfo.count - 1 }}</template>
          </template>
        </a>
      </slot>
    </template>
    <template #header>
      <!-- v-if in template instead of surrounding div necessary to work around strange bug with scoped style data attribute not being applied correctly when the surrounding div is not in the DOM at initial component render -->
      <slot name="header">
        <div v-if="info && info.area" class="size">
          <span class="number">{{ formattedArea.int }}</span>
          <div>
            <span class="fraction">{{ formattedArea.separator + formattedArea.fraction }}</span>
            <span class="unit">{{ $i18n.translateUnit('ha') }}</span>
          </div>
        </div>
      </slot>
    </template>
    <template v-if="!noMenu" #addon>
      <div>
        <QuickActions>
          <QuickActionIcon icon="tags" :title="SR_FieldRecordSystem.FieldGroups" @click="editingTags = true" />
          <QuickActionIcon v-if="$can('feature/highlight-migrated-fields') && migratedFields.includes(field.id)" title="migriert">
            <span style="color: Mediumslateblue;">
              <i class="fa fa-cloud-upload" />
            </span>
          </QuickActionIcon>
          <!-- <i class="fa fa-clone" :title="SR_FieldRecordSystem.CopySelectedGeometryToClipboard" @click="copyFeatureToClipboard"></i> -->
        </QuickActions>
        <div v-if="zoneMapsCounter" :title="$i18n.translate('frs.icons.zoneMapsCounter', {active: zoneMapsCounter.activeZoneMaps, inactive: zoneMapsCounter.inactiveZoneMaps})" @click.stop>
          <i class="mzk-icon fa fa-map" />
        </div>
        <BsModal v-if="editingTags" v-model="editingTags">
          <template #title>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TagEditor</IxRes>
          </template>
          <p style="margin-bottom: 15px">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.TagDescriptionLong</IxRes>
          </p>
          <TagEditor v-model="tags" :existing-tags="existingTags" />
          <button slot="footer" class="btn btn-default" @click.stop="editingTags = false">
            <IxRes>Common.SR_Common.Close</IxRes>
          </button>
        </BsModal>
      </div>
    </template>
    <LabelOnlyTagDisplay v-if="tags.length && showTags" slot="tags" :tags="tags" />
    <slot />
  </ListCard>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import {mapResources} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import {cultivationToName, cultivationTypeToShortcut} from '@frs/components/cultivation-overview/helpers'

import PermissionMixin from '@mixins/PermissionMixin'

import TagEditor from '@components/TagEditor'
import LabelOnlyTagDisplay from '@components/LabelOnlyTagDisplay'
import BsModal from '@components/bootstrap/BsModal'
import ListCard from '@components/card/ListCard'
import QuickActions from '@components/card/QuickActions'
import QuickActionIcon from '@components/card/QuickActionIcon'

import FieldBorderAvatar from './FieldBorderAvatar'

export default {
  components: {
    ListCard,
    QuickActions,
    QuickActionIcon,
    BsModal,
    TagEditor,
    LabelOnlyTagDisplay,
    FieldBorderAvatar
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    field: Object,
    selected: {
      validator: prop => typeof prop === 'boolean' || prop === null
    },
    noMenu: Boolean,
    showTags: Boolean,
    showCode: Boolean,
    showDetails: Boolean,
    loading: Boolean
  },
  data () {
    return {
      editingTags: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState({
      permissions: state => state.permissions
    }),
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field,
      fieldTags: state => state.userSettings.tags.fields,
      mixtures: state => state.catchCropMixtures.data.selection.orgUnit,
      migratedFields: state => state.migration.fields
    }),
    ...mapGetters('fieldRecordSystem', [
      'existingTags',
      'fieldFeaturesForCurrentOrgUnit',
      'harvestYear',
      'orgUnitId'
    ]),
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup,
      zoneMapIdsByFieldId: state => state.data.zoneMap.mapIdsByFieldId
    }),
    zoneMapsCounter () {
      const zoneMapIds = this.zoneMapIdsByFieldId[this.field.id]
      if (!zoneMapIds) {
        return null
      }

      const activeZoneMapsCount = zoneMapIds.filter(id => this.zoneMapLookup[id].active).length
      const inactiveZoneMapsCount = zoneMapIds.filter(id => this.zoneMapLookup[id].active === false).length

      return {activeZoneMaps: activeZoneMapsCount, inactiveZoneMaps: inactiveZoneMapsCount}
    },
    info () {
      // TODO refactor fieldData structure to remove checking, or redo data display to have loading state
      return this.fieldData[this.field.id] || {}
    },
    fieldCode () {
      return this.info.code ? this.info.code : this.field.code
    },
    tags: {
      get () {
        return this.fieldTags[this.field.id] || []
      },
      set (tags) {
        this.setFieldTags({id: this.field.id, tags})
      }
    },
    formattedArea () {
      const area = this.info.area.geometrical || this.info.area.official || 0
      const formatted = this.$i18n.format(area / 10000, 'f2')
      const separator = this.$i18n.format(0.1).replace(0, '').replace(1, '')
      const [int, fraction] = formatted.split(separator)
      return {
        int,
        fraction,
        separator
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'setFieldTags'
    ]),
    ...mapMutations('fieldRecordSystem/map', [
      'copyToClipboard'
    ]),
    copyFeatureToClipboard () {
      const feature = this.fieldFeaturesForCurrentOrgUnit.find(x => x.properties.id === this.field.id)
      this.copyToClipboard([feature])
      notifications.info(this.SR_FieldRecordSystem.GeometryCopiedToClipboard)
    },
    toCultivationPlan () {
      this.$router.push({name: 'cultivations', params: {year: this.harvestYear, orgUnitId: this.orgUnitId}, query: {selectedFieldId: this.field.id}})
    },
    toCultivationName (cultivation) {
      return cultivationToName(cultivation)
    },
    toCultivationShortcut (cultivation) {
      return cultivationTypeToShortcut(cultivation)
    }
  }
}
</script>

<style lang="scss" scoped>
.mzk-icon {
  display: flex;
  flex-direction: row-reverse;
  color: darkgray;
  margin: 0.3em;
  &:hover {
    cursor: default;
  }
}
.list-card-container:hover {
  cursor: pointer;
}
.missing-geometry-warning {
  opacity: 0.4;
}

.size {
  color: #DDD;
  margin-left: 0.25em;
  display: flex;
  // min-width: 6em;

  div {
    display: flex;
    flex-direction: column;
  }

  .number {
    font-size: 3.5em;
    line-height: 0.9em;
    font-weight: 100;
  }

  .fraction {
    font-size: 1.5em;
    font-weight: 100;
  }

  .unit {
    font-size: 1.5em;
    margin-top: -0.6em;
    font-weight: 100;
    align-self: flex-end;
  }
}

.cultivation-progress {
  display: flex;
  background-color: rgb(195, 205, 219);

  > div {
    height: 3px;
    background-color: rgb(109, 184, 232);
  }
}
</style>
