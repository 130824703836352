<template lang="html">
  <div class="row">
    <div class="col-md-6">
      <ToggleButton
        v-model="mode" value="borderRecognition_external"
        type="button" class="btn btn-default action-button btn-block"
        required
        :disabled="borderRecognitionActive" @input="enterBorderRecognitionMode({source: 'external'})"
      >
        <i v-if="borderRecognitionActive" class="fa fa-spinner fa-spin" />
        <i v-else class="fa fa-crosshairs" />
        Sollorow
      </ToggleButton>
    </div>
    <div class="col-md-6">
      <ToggleButton
        v-model="mode" value="borderRecognition_ixmap"
        type="button" class="btn btn-default action-button btn-block"
        required
        :disabled="borderRecognitionActive" @input="enterBorderRecognitionMode({source: 'ixmap', featureLoadingFunction})"
      >
        <i v-if="borderRecognitionActive" class="fa fa-spinner fa-spin" />
        <i v-else class="fa fa-crosshairs" />
        iXmap
      </ToggleButton>
    </div>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
import {transform} from 'ol/proj'

import {mapFormFields} from '@helpers/vuex'

import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      mode: state => state.editing.mode,
      borderRecognitionActive: state => state.borderRecognition.active
    }),
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.mode'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'enterBorderRecognitionMode'
    ]),
    featureLoadingFunction (coordinate) {
      const layer = this.$refs.wfsLayer.layer

      if (!layer) return []

      return this.$refs.wfsLayer.layer.getSource().getFeaturesAtCoordinate(transform(coordinate, 'EPSG:4326', 'EPSG:3857'))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
