<template lang="html">
  <TagDisplay
    :tags="tagObjects"
    :placeholder="placeholder"
    :button="button"
    :large="large"
    @click="$emit('click', $event.id)"
  />
</template>

<script>

import TagDisplay from './TagDisplay'

export default {
  components: {
    TagDisplay
  },
  props: {
    tags: Array,
    placeholder: String,
    button: String,
    large: Boolean
  },
  computed: {
    tagObjects () {
      return this.tags.map(tag => {
        return {
          label: tag,
          id: tag
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
