<template lang="html">
  <div>
    <select
      ref="input" type="text"
      multiple="true"
      :placeholder="SR_FieldRecordSystem.TagDescriptionShort"
    />
  </div>
</template>

<script>
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_FieldRecordSystem: 'Areas.FieldRecordSystem.SR_FieldRecordSystem'
}
export default {
  mixins: [makeResourceMixin(resources)],
  model: {
    prop: 'tags'
  },
  props: {
    tags: Array, // tags
    existingTags: Array // tags
  },
  data () {
    return {
      editing: false,
      blockEvents: false
    }
  },
  computed: {
    selectize () {
      return this.$refs.input.selectize
    }
  },
  watch: {
    existingTags (tags) {
      this.blockEvents = true
      // this.selectize.clear(true)
      this.selectize.clearOptions()
      for (const tag of tags) {
        this.selectize.addOption({
          value: tag,
          text: tag
        })
        if (this.tags.includes(tag)) {
          this.selectize.addItem(tag)
        }
      }
      this.blockEvents = false
    },
    tags (tags) {
      this.blockEvents = true
      this.selectize.clear()
      for (const tag of tags) {
        this.selectize.addItem(tag)
      }
      this.blockEvents = false
    }
  },
  mounted () {
    const $select = $(this.$refs.input)

    $select.selectize({
      plugins: ['remove_button', 'restore_on_backspace'],
      delimiter: ',',
      persist: false,
      options: this.existingTags.map(tag => ({text: tag, value: tag})),
      items: [...this.tags],
      create (input) {
        return {
          value: input,
          text: input
        }
      },
      createFilter: (input) => !this.tags.includes(input)
    })

    this.selectize.on('item_add', tag => {
      if (this.blockEvents) {
        return
      }
      const tags = new Set(this.tags)
      tags.add(tag)
      this.$emit('input', [...tags])
      this.$emit('added', tag)
    })

    this.selectize.on('item_remove', tag => {
      if (this.blockEvents) {
        return
      }
      this.$emit('input', this.tags.filter(x => x !== tag))
      this.$emit('removed', tag)
    })
  },
  beforeDestroy () {
    this.selectize.destroy()
  }
}
</script>

<style lang="scss" scoped>
.label-custom {
  color: #777;
  border: 1px solid #777;
  background-color: white;
}
</style>
