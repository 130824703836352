<template lang="html">
  <div class="row">
    <div class="col-md-6">
      <ToggleButton
        v-model="mode" :value="appendGuideIfNecessary('selection')"
        class="btn-block"
        required
      >
        <i class="fa fa-hand-paper-o" aria-hidden="true" />
        <IxRes>Common.SR_Common.Selection</IxRes>
      </ToggleButton>

      <ToggleButton
        v-model="mode" :value="appendGuideIfNecessary('addPolygon')"
        class="btn-block"
        required
      >
        <FontAwesomeIcon :icon="drawPolygonIcon" />
        <IxRes>Common.SR_Common.Polygon</IxRes>
      </ToggleButton>

      <ToggleButton
        v-model="mode" :value="appendGuideIfNecessary('addPoint')"
        class="btn-block"
        required
      >
        <FontAwesomeIcon :icon="drawCircleIcon" />
        <IxRes>Common.SR_Common.Point</IxRes>
      </ToggleButton>

      <ToggleButton
        v-model="mode" :value="appendGuideIfNecessary('edit')"
        class="btn-block"
        required
      >
        <i class="fa fa-pencil" aria-hidden="true" />
        <IxRes>Common.SR_Common.EditLabel</IxRes>
      </ToggleButton>

      <ToggleButton
        v-if="!guides"
        v-model="mode" value="cut"
        class="btn-block"
        required
      >
        <i class="fa fa-expand" aria-hidden="true" required /> <IxRes>Common.SR_Common.Split</IxRes>
      </ToggleButton>
    </div>
    <div class="col-md-6">
      <ToggleButton
        v-model="mode" :value="appendGuideIfNecessary('addLine')"
        class="btn-block"
        required
      >
        <i class="fa fa-plus" aria-hidden="true" /> <IxRes>Common.SR_Common.Line</IxRes>
      </ToggleButton>

      <button
        class="btn btn-default action-button btn-block" type="button"
        :disabled="!clipboard.length"
        @click.prevent="paste(guides ? 'guides': 'editing')"
      >
        <i class="fa fa-clipboard" />
        <IxRes>Common.SR_Common.Insert</IxRes>
      </button>

      <button
        v-if="!guides" class="btn btn-default action-button btn-block"
        :disabled="!canMergePolygons"
        @click="mergeSelectedFeatures"
      >
        <i class="fa fa-compress" aria-hidden="true" required /> <IxRes>Common.SR_Common.Merge</IxRes>
      </button>

      <ImportButton :layer="guides ? 'guides': 'editing'" />
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import union from '@turf/union'

import {mapFormFields} from '@helpers/vuex'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCircle, faVectorSquare, faStopCircle} from '@fortawesome/free-solid-svg-icons'

import ToggleButton from '@components/ToggleButton'

import ImportButton from './ImportButton'

export default {
  components: {
    ToggleButton,
    ImportButton,
    FontAwesomeIcon
  },
  props: {
    guides: Boolean
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      clipboard: state => state.clipboard
    }),
    ...mapGetters('fieldRecordSystem/map', [
      'selectedFeatures'
    ]),
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.mode',
      'editing.selectedIds',
      'editing.activeFeatures',
      'editing.guideFeatures'
    ]),
    canMergePolygons () {
      const selectedPolygons = this.selectedFeatures.filter(x => x.geometry.type === 'Polygon')
      return selectedPolygons.length >= 2
    },
    drawHoleIcon () {
      return faStopCircle
    },
    drawPolygonIcon () {
      return faVectorSquare
    },
    drawCircleIcon () {
      return faCircle
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/map', [
      'recordFeatures',
      'pasteAsPolygons'
    ]),
    appendGuideIfNecessary (value) {
      return this.guides
        ? value + 'Guide'
        : value
    },
    mergeSelectedFeatures () {
      this.recordFeatures()

      const polygons = this.selectedFeatures.filter(x => x.geometry.type === 'Polygon')

      const combinedPolygon = union(...polygons)

      if (combinedPolygon.geometry.type === 'Polygon') {
        combinedPolygon.properties = polygons[0].properties

        const otherFeatures = this.activeFeatures
          .filter(x => !this.selectedFeatures.includes(x))

        this.selectedIds = []
        this.activeFeatures = [...otherFeatures, combinedPolygon]
      }
    },
    paste (layer) {
      this.recordFeatures()
      this.pasteAsPolygons(layer)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
