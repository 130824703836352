<template lang="html">
  <div>
    <QuickModeInteractions />

    <!-- NOTE addPolygon is already in QuickModeInteractions -->
    <DrawInteraction
      v-if="mode === 'addLine'"
      key="addLine"
      type="LineString"
      @finish="onDrawFinish"
    />
    <DrawInteraction
      v-if="mode === 'addPoint'"
      key="addPoint"
      type="Point"
      @finish="onDrawFinish"
    />
    <DrawInteraction
      v-if="mode === 'addPolygonGuide'"
      key="addPolygonGuide"
      type="Polygon"
      @finish="onDrawFinish"
    />
    <DrawInteraction
      v-if="mode === 'addLineGuide'"
      key="addLineGuide"
      type="LineString"
      @finish="onDrawFinish"
    />
    <DrawInteraction
      v-if="mode === 'addPointGuide'"
      key="addPointGuide"
      type="Point"
      @finish="onDrawFinish"
    />

    <MeasurementInteraction
      v-if="mode === 'measureArea'"
      key="measureArea"
      type="Polygon"
    />
    <MeasurementInteraction
      v-if="mode === 'measureDistance'"
      key="measureDistance"
      type="LineString"
    />

    <CutInteraction
      v-if="mode === 'cut'"
      :features="activeFeatures"
      @cut="onCutFinish"
    />

    <SnapInteraction
      v-if="mode.startsWith('add') || mode.startsWith('edit') || mode.startsWith('measure') || mode === 'cut'"
      :key="mode + 'Snap'"
      :features="snapFeatures"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import DrawInteraction from '@components/map/interactions/DrawInteraction'
import MeasurementInteraction from '@components/map/interactions/MeasurementInteraction'
import CutInteraction from '@components/map/interactions/CutInteraction'
import SnapInteraction from '@components/map/interactions/SnapInteraction'
import QuickModeInteractions from '@frs/components/geometry-editing/quick-mode-editing/QuickModeInteractions'

let count = 0

export default {
  components: {
    DrawInteraction,
    SnapInteraction,
    MeasurementInteraction,
    CutInteraction,
    QuickModeInteractions
  },
  computed: {
    ...mapState('fieldRecordSystem/map', {
      mode: state => state.editing.mode,
      snapping: state => state.editing.snapping
    }),
    ...mapFormFields('fieldRecordSystem/map', [
      'editing.activeFeatures',
      'editing.guideFeatures'
    ]),
    snapFeatures () {
      if (!this.snapping) return []

      return this.activeFeatures.concat(this.guideFeatures)
    }
  },
  methods: {
    onDrawFinish (feature) {
      feature.properties = {id: `new${count++}`}
      if (this.guides) {
        this.guideFeatures = [...this.guideFeatures, feature]
      } else {
        this.activeFeatures = [...this.activeFeatures, feature]
      }
    },
    onCutFinish (results) {
      const cutFeatures = results.map(x => x.feature)
      const cutParts = results
        .reduce((features, partialResult) => features.concat(partialResult.parts.features), [])

      cutParts.forEach(feature => {
        feature.properties = {id: `new${count++}`}
      })

      const otherFeatures = this.activeFeatures.filter(x => !cutFeatures.includes(x))

      this.activeFeatures = [...otherFeatures, ...cutParts]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
