<template lang="html">
  <FrsFieldSelection
    :value="selectedFieldIds"
    :filter="({id}) => samplingYearsByFieldId[id] !== '1'"
    @toggle="onToggle"
  >
    <template #subtitle="{field}">
      <div class="field-item">
        <span v-if="samplingYearsByFieldId[field.id] === '1'" class="subtitle" title="year">
          <IxRes>bf.fieldItem.noProbingDate</IxRes>
        </span>
        <span v-else class="subtitle" title="year">
          <IxRes>bf.fieldItem.lastProbingDate</IxRes>:
          {{ samplingYearsByFieldId[field.id] }}
        </span>
        <span v-if="nutrientMapsByFieldId[field.id]" class="subtitle">
          {{ nutrientMapsByFieldId[field.id].map(map => $i18n.translate(`bf.labels.nutrient_${map.nutrient}`)).join(', ') }}
        </span>
      </div>
    </template>
  </FrsFieldSelection>
</template>

<script>
import moment from 'moment'
import {mapState, mapActions, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'

export default {
  components: {
    FrsFieldSelection
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization', {
      fieldStatisticsLookup: state => state.data.fieldStatisticsLookup.statistics,
      nutrientMapsByFieldId: state => state.data.fieldStatisticsLookup.nutrientMapsByFieldId,
      simplifiedGeometries: state => state.data.simplifiedGeometries
    }),
    ...mapFormFields('fieldRecordSystem/basicFertilization/nutrientMaps', [
      'selectedFieldId'
    ]),
    selectedFieldIds () {
      return [this.selectedFieldId]
    },
    fieldIdsWithNutrientMaps () {
      return Object.keys(this.nutrientMapsByFieldId)
    },
    samplingYearsByFieldId () {
      const lookup = {}

      for (const fieldId in this.fieldStatisticsLookup) {
        lookup[fieldId] = moment(this.fieldStatisticsLookup[fieldId].lastSamplingDate).year().toString()
      }
      return lookup
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapActions('fieldRecordSystem/basicFertilization/nutrientMaps', [
      'loadNutrientMaps'
    ]),
    ...mapMutations('fieldRecordSystem/basicFertilization/nutrientMaps', [
      'setSelectedFieldId'
    ]),
    onToggle (fieldId) {
      this.setSelectedFieldId(fieldId)

      this.loadNutrientMaps(fieldId)

      const data = this.simplifiedGeometries[fieldId]
      if (data) {
        this.focus(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.field-item {
  display: flex;
  flex-direction: column;
}
</style>
