<template lang="html">
  <div>
    <MeasurementOverlay
      v-if="position"
      :position="position"
      centered
    >
      {{ size }}
    </MeasurementOverlay>

    <IxVectorLayer
      :features="previousMeasurements"
      :vector-style="vectorStyle"
    />
  </div>
</template>

<script>
import {unByKey} from 'ol/Observable'
import Draw from 'ol/interaction/Draw'
import Polygon from 'ol/geom/Polygon'
import LineString from 'ol/geom/LineString'
import {transform} from 'ol/proj'

import {geoJsonFormat} from '@helpers/openlayers/features'
import {computeSizeFormatted} from '@helpers/openlayers/format'

import IxVectorLayer from '@components/map/IxVectorLayer'
import MeasurementOverlay from '@components/map/overlays/MeasurementOverlay'

import {measurementPreview, previousMeasurements} from './styles'

export default {
  inject: [
    'getMap'
  ],
  components: {
    IxVectorLayer,
    MeasurementOverlay
  },
  props: {
    type: {
      type: String,
      validator: type => ['Polygon', 'LineString'].indexOf(type) !== -1
    }
  },
  data () {
    return {
      size: null,
      position: null,
      previousMeasurements: []
    }
  },
  computed: {
    vectorStyle () {
      return previousMeasurements
    }
  },
  methods: {
    init () {
      this.drawInteraction = new Draw({
        type: this.type,
        freehandCondition: () => false,
        style: [measurementPreview]
      })
      this.map.addInteraction(this.drawInteraction)

      this.listeners.push(this.drawInteraction.on('drawstart', this.onDrawStart))
      this.listeners.push(this.drawInteraction.on('drawend', this.onDrawEnd))

      document.addEventListener('keydown', this.onKeyDown)
    },
    onPreviewChange (event) {
      let location = null

      if (event.target instanceof Polygon) {
        location = event.target.getInteriorPoint().getCoordinates()
      } else if (event.target instanceof LineString) {
        location = event.target.getLastCoordinate()
      }

      const geometry = geoJsonFormat.writeGeometryObject(event.target)
      this.size = computeSizeFormatted(geometry)
      this.position = location && transform(location, 'EPSG:3857', 'EPSG:4326')
    },
    onDrawStart (event) {
      this.previewChangeListener = event.feature.getGeometry().on('change', this.onPreviewChange)

      document.addEventListener('keydown', this.onKeyDown)
    },
    onDrawEnd (event) {
      const feature = geoJsonFormat.writeFeatureObject(event.feature)
      this.previousMeasurements.push(feature)

      unByKey(this.previewChangeListener)
      this.size = null
      this.position = null

      document.removeEventListener('keydown', this.onKeyDown)
    },
    onKeyDown (event) {
      if (event.keyCode === 27) { // esc
        try {
          this.drawInteraction.finishDrawing()
        } catch (error) {
          console.error(error)
        }
      }
    }
  },
  created () {
    this.listeners = []

    this.getMap().then(map => {
      this.map = map
      this.init()
    })
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeyDown)

    if (this.drawInteraction) {
      this.map.removeInteraction(this.drawInteraction)
    }
    if (this.previewChangeListener) {
      unByKey(this.previewChangeListener)
    }

    this.listeners.forEach(key => {
      unByKey(key)
    })
  }
}
</script>

<style lang="scss" scoped>
</style>
