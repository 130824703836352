<template lang="html">
  <div class="bf-field-selection">
    <FrsFieldSelection
      :value="selectedFieldIds"
      :filter="filterIds"
      @toggle="onToggle"
    >
      <template #subtitle="{field}">
        <span v-if="samplingYearsByFieldId[field.id] === '1'" class="subtitle" title="year">
          <IxRes>bf.fieldItem.noProbingDate</IxRes>
        </span>
        <span v-else class="subtitle" title="year">
          <IxRes>bf.fieldItem.lastProbingDate</IxRes>:
          {{ samplingYearsByFieldId[field.id] }}
        </span>
      </template>
      <template #header="{field}">
        <div class="size">
          <span class="number">
            {{ numberOfPointsByFieldId[field.id] }}
          </span>
          <div>
            <span class="seperate-text-top">
              <IxRes>bf.orderCreation.fieldSelection.probing</IxRes>
            </span>
            <span class="seperate-text-bottom">
              <IxRes>bf.orderCreation.fieldSelection.points</IxRes>
            </span>
          </div>
        </div>
      </template>
    </FrsFieldSelection>
    <FrsLoadingIndicator :requests="['bf.field.statistics']" />
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'

import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    FrsFieldSelection,
    FrsLoadingIndicator
  },
  data () {
    return {
      fieldId: []
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/basicFertilization', {
      simplifiedGeometries: state => state.data.simplifiedGeometries
    }),
    ...mapState('fieldRecordSystem/basicFertilization/resultImport', {
      order: state => state.order,
      selectedFieldId: state => state.manual.selectedFieldId
    }),
    ...mapState('fieldRecordSystem/basicFertilization/orderExport', [
      'selectedOrder'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization', {
      fieldStatisticsLookup: state => state.data.fieldStatisticsLookup.statistics
    }),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup'
    ]),
    fieldIdsFromCurrentOrder () {
      switch (this.$store.state.fieldRecordSystem.ui.view.right) {
      case 'probingOrderExport':
        return this.selectedOrder
      case 'probingResultManualSettings':
      case 'probingResultManualValues':
      case 'probingResultUpload':
        return this.order
      default:
        return null
      }
    },
    fieldIdsFromOrder () {
      if (this.fieldIdsFromCurrentOrder) {
        var fieldIds = []
        Object.values(this.fieldIdsFromCurrentOrder.fieldInfoByGeometry).map(x => x.map(y => fieldIds.push(y.fieldId)))
        return fieldIds
      }
      return Object.keys(this.simplifiedGeometries)
    },
    samplingYearsByFieldId () {
      const lookup = {}

      for (const fieldId in this.fieldStatisticsLookup) {
        lookup[fieldId] = moment(this.fieldStatisticsLookup[fieldId].lastSamplingDate).year().toString()
      }

      return lookup
    },
    numberOfPointsByFieldId () {
      const lookup = {}

      for (const fieldId in this.fieldStatisticsLookup) {
        lookup[fieldId] = this.fieldStatisticsLookup[fieldId].numberProbingSites
      }

      return lookup
    },
    selectedFieldIds () {
      if (this.$store.state.fieldRecordSystem.ui.view.right !== 'probingResultManualValues') return []

      return [this.selectedFieldId]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapMutations('fieldRecordSystem/basicFertilization/resultImport', [
      'toggleSelectedFieldId'
    ]),
    onToggle (id) {
      this.toggleSelectedFieldId(id)

      const data = this.simplifiedGeometries[id]
      if (data) {
        this.focus(data)
      }
    },
    filterIds (field) {
      return this.fieldIdsFromOrder.indexOf(field.id) >= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.bf-field-selection {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.size {
  color: #DDD;
  margin-left: 0.25em;
  display: flex;

  div {
    display: flex;
    flex-direction: column;
  }

  .number {
    font-size: 3.5em;
    line-height: 0.9em;
    font-weight: 100;
  }

  .seperate-text-top {
    font-size: 1.5em;
    font-weight: 100;
  }

  .seperate-text-bottom {
    font-size: 1.5em;
    margin-top: -0.6em;
    font-weight: 100;
    align-self: flex-end;
  }
}
</style>
