<template lang="html">
  <FrsFeatureAvatar>
    <template v-for="({color, polygons}, i) in cultivationData">
      <polygon
        v-for="(polygon, j) in polygons" :key="`cultivation-${i}-${j}`"
        :fill="color"
        :points="polygon"
      />
    </template>
    <template v-if="borderPolygons">
      <polygon
        v-for="(polygon, i) in borderPolygons" :key="`border-${i}`"
        stroke="black"
        stroke-width="4"
        fill="transparent"
        :points="polygon"
      />
    </template>
  </FrsFeatureAvatar>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {bbox, helpers} from '@turf/turf'

import {toSvgPolygons} from '@helpers/geojson'
import {cultivationToColor} from '@frs/helpers/colors'

import FrsFeatureAvatar from '@frs/components/base/FrsFeatureAvatar'

export default {
  components: {
    FrsFeatureAvatar
  },
  props: {
    fieldId: String
  },
  computed: {
    ...mapState('masterData', {
      cropUsages: state => state.cropUsages
    }),
    ...mapState('fieldRecordSystem', {
      fieldData (state) {
        return state.data.field[this.fieldId] || {}
      }
    }),
    ...mapGetters('fieldRecordSystem/cultivationPlanning', [
      'cultivationsByFieldId'
    ]),
    bbox () {
      const collection = {
        type: 'FeatureCollection',
        features: [
          helpers.feature(this.fieldData.simplifiedGeometry),
          ...this.cultivations.map(x => helpers.feature(x.simplifiedGeometry))
        ]
      }
      return bbox(collection)
    },
    borderPolygons () {
      return toSvgPolygons(this.fieldData.simplifiedGeometry, this.bbox)
    },
    cultivations () {
      return this.cultivationsByFieldId[this.fieldId] || []
    },
    cultivationData () {
      return this.cultivations
        .map(cultivation => ({
          color: cultivationToColor(cultivation, this.cropUsages[cultivation.cropUsageId]),
          polygons: toSvgPolygons(cultivation.simplifiedGeometry, this.bbox)
        }))
        .filter(x => x.polygons)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
