<template>
  <div>
    <div>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldGroupsAssignment</IxRes>
    </div>

    <div style="margin-top: 20px;">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Fields</IxRes>: *
    </div>
    <VueSelectize
      v-model="selectedFields" allow-empty
      :fields="fields"
      :options="fieldsForCurrentOrgUnit" multiple
    />

    <div>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldGroups</IxRes>: *
    </div>
    <TagEditor v-model="selectedTags" :existing-tags="existingTags" />

    <div style="margin-top: 40px;">
      <button :disabled="!isValid" class="btn btn-success" @click.prevent="save">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SaveAssignment</IxRes>
      </button>
      <button type="reset" class="btn btn-default pull-right" @click.prevent="cancel">
        <IxRes>Common.SR_Common.Cancel</IxRes>
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {uniq} from 'lodash'

import TagEditor from '@components/TagEditor'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize,
    TagEditor
  },
  data () {
    return {
      selectedFields: [],
      selectedTags: [],
      fields: {text: 'name', value: 'id'}
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem', [
      'existingTags',
      'fieldsForCurrentOrgUnit'
    ]),
    ...mapState('fieldRecordSystem', {
      tagsByField: state => state.userSettings.tags.fields
    }),
    isValid () {
      return this.selectedTags.length > 0 && this.selectedFields.length > 0
    }
  },
  methods: {
    save () {
      const result = {}
      this.selectedFields.forEach((f) => {
        const tags = (this.tagsByField[f.id] || []).concat(this.selectedTags)
        result[f.id] = uniq(tags)
      })
      this.$emit('save', result)
      this.clear()
    },
    cancel () {
      this.$emit('cancel')
      this.clear()
    },
    clear () {
      this.selectedFields = []
      this.selectedTags = []
    }
  }
}
</script>

<style lang="css" scoped>
</style>
