<template lang="html">
  <div>
    <template v-if="location.orgUnitId">
      <hr>

      <FrsRocketHeaderSettings />

      <div v-if="orgUnit && !orgUnit.visibleOnly" class="org-unit-buttons" @click.stop>
        <IxButton
          class="fancy-button" icon="plus-square-o"
          rounded
          large @click="onNewOrgUnit"
        >
          <IxRes>AddSubCompany</IxRes>
        </IxButton>
        <IxButton
          class="fancy-button" icon="plus-square-o"
          rounded
          large @click="createNewField"
        >
          <IxRes>AddField</IxRes>
        </IxButton>
        <IxButton
          class="fancy-button" icon="cloud-upload"
          rounded
          large @click="showEntityImportModal = true"
        >
          <IxRes>ImportCompanyStructure</IxRes>
        </IxButton>
      </div>
    </template>
    <template v-else>
      <IxButton
        class="entity-import-button" icon="cloud-upload"
        rounded
        large block
        @click="showEntityImportModal = true"
      >
        <div class="action-panel">
          <div class="action-title">
            <IxRes>EntityImportRootButtonTitle</IxRes>
          </div>
          <div class="action-subtitle">
            <IxRes>EntityImportRootButtonSubtitle</IxRes>
          </div>
        </div>
      </IxButton>

      <IxButton
        class="new-root-button" icon="plus-square-o"
        rounded
        large block
        @click="onNewOrgUnit"
      >
        <div class="action-panel">
          <div class="action-title">
            <IxRes>NewRootOrgButtonTitle</IxRes>
          </div>
          <div class="action-subtitle">
            <IxRes>NewRootOrgButtonSubtitle</IxRes>
          </div>
        </div>
      </IxButton>
    </template>

    <EntityImportModal v-model="showEntityImportModal" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'

import IxButton from '@components/IxButton'
import EntityImportModal from '@frs/components/entity-import/EntityImportModal'

import FrsRocketHeaderSettings from './FrsRocketHeaderSettings'

export default {
  translationNamespace: 'Areas.FieldRecordSystem.SR_FieldRecordSystem',
  components: {
    IxButton,
    FrsRocketHeaderSettings,
    EntityImportModal
  },
  data () {
    return {
      showEntityImportModal: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/navigation', {
      location: state => state.location
    }),
    ...mapGetters('fieldRecordSystem', [
      'orgUnit'
    ])
  },
  methods: {
    ...mapMutations('fieldRecordSystem', [
      'setRightView',
      'setIsNewEntity'
    ]),
    onNewOrgUnit () {
      // TODO check if this is still necessary, should be obsolete now that form is backed by vuex
      this.setIsNewEntity(true)
      // TODO call action instead of setRightView directly
      this.setRightView('newCompanyData')
    },
    createNewField () {
      this.$router.push({name: 'newField', params: {orgUnitId: this.location.orgUnitId}})
    }
  }
}
</script>

<style lang="scss" scoped>
.org-unit-buttons {
  display: flex;
  margin-top: 5px;

  .fancy-button {
    flex: 1;
  }
  .fancy-button + .fancy-button {
    margin-left: 5px;
  }
}

.new-root-button, .entity-import-button {
  margin: 6px 0;

  ::v-deep .fa {
    font-size: 1.6em;
  }

  .action-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
  }
  .action-title {
    font-size: 1.2em;
  }
  .action-subtitle {
    color: darkgray;
  }
}

hr {
  border-color: rgb(171, 171, 171);
  margin: 0.3em 0;
}
</style>
