<template lang="html">
  <RocketNav
    v-model="selectedId"
    :current-org="orgUnit"
    :parent-org="parent"
    :items="entities"
    :disabled="disabled"
    :sorting-function="sortingFunction"
    loading-enabled
    @focus="focus"
  >
    <template #header>
      <FrsRocketHeaderContent />
    </template>

    <template #field="{item, selected}">
      <FieldItem
        :field="item"
        :selected="selected" show-tags
        :show-code="showFieldCodes"
        :loading="fieldDetails[item.id] && fieldDetails[item.id].wkt === undefined"
        @focus="focus"
      />
    </template>
  </RocketNav>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import {flattenEntityForest, filters} from '@helpers/tree'
import {mapUiFlags} from '@frs/helpers/ui'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import RocketNav from '@components/nav/RocketNav'
import FrsRocketHeaderContent from './FrsRocketHeaderContent'

import FieldItem from './items/FieldItem'

export default {
  components: {
    RocketNav,
    FrsRocketHeaderContent,
    FieldItem
  },
  mixins: [
    MirrorRouteMixin
  ],
  computed: {
    ...mapUiFlags([
      'dashboard.showFieldCodes',
      'dashboard.hideEmptyOrgs'
    ]),
    ...mapState('fieldRecordSystem/navigation', {
      entityForest: state => state.entityForest,
      location: state => state.location,
      simplifiedGeometries: state => state.simplifiedGeometries
    }),
    ...mapState('fieldRecordSystem', {
      fieldDetails: state => state.data.field,
      editing: state => state.map.editing
    }),
    ...mapGetters('fieldRecordSystem', [
      'orgUnit',
      'fieldsForCurrentOrgUnit'
    ]),
    ...mapGetters('fieldRecordSystem/navigation', [
      'entityLookup',
      'parentLookup'
    ]),
    selectedId: {
      get () {
        return this.$route.params.fieldId || this.$route.params.orgUnitId
      },
      set (id) {
        this.onNavigate(id)
      }
    },
    parent () {
      return this.entityLookup[this.parentLookup[this.location.orgUnitId]]
    },
    entities () {
      if (this.location.orgUnitId && this.cultivationFilter && Object.keys(this.cultivationFilter).length > 0) {
        const fieldIdsForFilteredCultivation =
          Object.values(this.orgUnitCultivations)
            .filter(x => Object.values(this.cultivationFilter).some(cultfilter => x.cropUsageId === cultfilter.cropUsageId &&
              x.type === cultfilter.type &&
              x.mixtureId === cultfilter.mixtureId))
            .reduce((set, {fieldIds}) => {
              fieldIds.forEach(fieldId => set.add(fieldId))
              return set
            }, new Set())

        const filteredFields = this.fieldsForCurrentOrgUnit.filter(field => {
          if ([...fieldIdsForFilteredCultivation].includes(field.id)) {
            return true
          }
          return false
        })

        return filteredFields
      } else if (this.orgUnit) {
        return this.hideEmptyOrgs
          ? this.orgUnit.children.filter(filters.hasFieldsSomewhere)
          : this.orgUnit.children
      } else {
        return this.hideEmptyOrgs
          ? this.entityForest.filter(filters.hasFieldsSomewhere)
          : this.entityForest
      }
    },
    disabled () {
      return this.editing.active && this.editing.flavor === 'quick'
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/navigation', [
      'loadSimplifiedFieldGeometriesForCurrentOrgUnit'
    ]),
    sortingFunction (a, b) {
      const aCode = a.type === 'field' && this.fieldDetails[a.id] ? this.fieldDetails[a.id].code : ''
      const bCode = b.type === 'field' && this.fieldDetails[b.id] ? this.fieldDetails[b.id].code : ''

      if (this.showFieldCodes) {
        return (aCode + a.name).localeCompare(bCode + b.name)
      } else {
        return (a.name + aCode).localeCompare(b.name + bCode)
      }
    },
    async onNavigate (id) {
      const entity = this.entityLookup[id]
      await new Promise(resolve => setTimeout(resolve, 0))

      if (!entity) {
        this.$router.push({name: 'home'})
      } else if (entity.type === 'org' && (this.location.fieldId || this.location.orgUnitId !== id)) {
        this.handleNavigationToOrgUnit(id)
      } else if (entity.type === 'field' && this.location.fieldId !== id) {
        this.handleNavigationToField(id)
      }
    },
    handleNavigationToOrgUnit (id) {
      const route = this.$route

      // navigate from orgUnit to another orgUnit and stay on the current route
      if (route.params.orgUnitId && route.meta.allowEntityChange) {
        this.$router.push({name: route.name, params: {...route.params, orgUnitId: id}})
      } else if (route.params.fieldId && route.meta.mirrorRoute) {
        // navigate from field to orgUnit and display the same component by using mirror route
        this.$router.push({name: this.createMirrorRoute(route.name), params: {...route.params, orgUnitId: id, fieldId: null}})
      } else {
        this.$router.push({name: 'orgDashboard', params: {orgUnitId: id}})
      }
    },
    handleNavigationToField (id) {
      const route = this.$route

      // navigate from orgUnit to another orgUnit and stay on the current route
      if (route.params.fieldId && route.meta.allowEntityChange) {
        this.$router.push({name: route.name, params: {...route.params, fieldId: id}})
      } else if (route.params.orgUnitId && route.meta.mirrorRoute) {
        // navigate from orgUnit to field and display the same component by using mirror route
        this.$router.push({name: this.createMirrorRoute(route.name), params: {...route.params, fieldId: id, orgUnitId: null}})
      } else {
        this.$router.push({name: 'fieldDashboard', params: {fieldId: id}})
      }
    },
    focus (id) {
      if (!id) return

      const getGeometry = field => this.simplifiedGeometries[field.id]

      const entity = this.entityLookup[id]

      if (entity.type === 'field') {
        this.$store.dispatch('fieldRecordSystem/map/focus', [getGeometry(entity)].filter(x => x))
      } else {
        const geometries = flattenEntityForest([entity]).filter(filters.fields).map(getGeometry).filter(x => x)
        this.$store.dispatch('fieldRecordSystem/map/focus', geometries)
      }
    }
  },
  watch: {
    location: {
      immediate: true,
      deep: true,
      async handler (location) {
        await this.loadSimplifiedFieldGeometriesForCurrentOrgUnit()
        this.focus(location.fieldId || location.orgUnitId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
