<template lang="html">
  <div class="rocket-nav">
    <RocketHeader
      :current-org="currentOrg"
      :parent-org="parentOrg"
      :selected-id="value"
      @navigate="onNavigate"
      v-on="inheritedListeners"
    >
      <slot name="header" />
    </RocketHeader>

    <div class="entity-list" style="position: relative;">
      <slot name="info" />
      <transition-group
        name="slide" tag="div"
        :class="[direction]"
        mode="out-in"
      >
        <div
          v-for="(item, i) in sortedItems"
          :id="`rocketnav_card_${i}`"
          :key="item.id"
          :class="{selected: item.id === value}"
          class="card-item"
          style="position: relative;"
          @click="onNavigate(item)"
        >
          <slot :name="item.type" :item="item" :selected="item.id === value">
            <OrgUnitItem
              v-if="item.type === 'org'"
              :org-unit="item"
              :selected="item.id === value"
              v-on="inheritedListeners"
            />
          </slot>
        </div>
      </transition-group>
      <br>
    </div>

    <DarkenPanel :display="disabled" />
  </div>
</template>

<script>
import DarkenPanel from '@frs/components/geometry-editing/DarkenPanel'

import RocketHeader from './RocketHeader'
import OrgUnitItem from './OrgUnitItem'

export default {
  components: {
    RocketHeader,
    OrgUnitItem,
    DarkenPanel
    // FrsLoadingIndicator
  },
  props: {
    value: String, // selected id
    currentOrg: Object,
    parentOrg: Object,
    items: Array,
    disabled: Boolean,
    sortingFunction: {
      type: Function,
      default: () => (a, b) => a.name.localeCompare(b.name)
    }
  },
  data () {
    return {
      direction: null
    }
  },
  computed: {
    sortedItems () {
      const items = [...this.items]

      return items.sort(this.sortingFunction)
    },
    itemIndices () {
      const indices = {}
      this.sortedItems.forEach((item, index) => {
        indices[item.id] = index
      })
      return indices
    },
    inheritedListeners () {
      const listeners = {}

      if (this.$listeners.focus) {
        listeners.focus = this.$listeners.focus
      }

      return listeners
    }
  },
  methods: {
    scrollTo (itemId) {
      const index = this.itemIndices[itemId]
      // NOTE does not do what it seems like, the actual entity card selected from the DOM is not the same entity corresponding to `itemId` due to timing issues. Works only when triggered from the `itemIndices` watcher if `index < this.entities.length` **before** the currently running update, as the DOM is not updated yet.
      const element = document.getElementById(`rocketnav_card_${index}`)

      if (element) {
        element.scrollIntoView()
      }
    },
    onNavigate (item) {
      if (!item || (item.children && this.currentOrg && item.children.find(x => x.id === this.currentOrg.id))) {
        this.direction = 'up'
      } else {
        this.direction = 'down'
      }

      this.$emit('input', item ? item.id : null)
    }
  },
  watch: {
    itemIndices (newIndices, oldIndices) {
      if (!this.value) return

      const newIndex = newIndices[this.value]
      const oldIndex = oldIndices[this.value]

      if (newIndex !== undefined && oldIndex !== undefined && newIndex !== oldIndex) {
        this.scrollTo(this.value)
      }
    }
  },
  mounted () {
    if (this.value) {
      this.scrollTo(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.rocket-nav {
  display: flex;
  flex-direction: column;
  min-height: 0; // firefox scrollbar issue
}
.entity-list {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}
.buttons {
  display: flex;

  button {
    flex: 1;
  }
}
.slide-enter {
  opacity: 0;
}
.slide-enter-active {
  transition: opacity .5s, transform .5s;
}
.down .slide-enter {
  transform: translateX(40%);
}
.up .slide-enter {
  opacity: 0;
  transform: translateX(-40%);
}
.card-item {
  cursor: pointer;

  + .card-item {
    margin-top: 5px;
  }
}
</style>
