<template>
  <BsModal
    v-model="showModal"
    :loading="saving"
    size="xlarge"
    blocking
  >
    <RelocationControl
      v-if="entityStructureEditing"
      :org-units="entityForest"
      @cancel="handleCancel"
      @save="handleSave"
      @initControl="onInitControl"
    />
  </BsModal>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'

import RelocationControl from './RelocationControl'

export default {
  components: {
    RelocationControl,
    BsModal
  },
  data () {
    return {
      saving: false,
      showModal: false
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem/navigation', {
      entityForest: state => state.entityForest
    }),
    ...mapFormFields('fieldRecordSystem', [
      'ui.mainPageLoading',
      'ui.entityStructureEditing'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'saveRestructuredOrgTree'
    ]),
    onInitControl (value) {
      this.mainPageLoading = !value
      this.showModal = value
    },
    handleCancel () {
      this.showModal = false
      this.entityStructureEditing = false
    },
    async handleSave (history) {
      this.saving = true
      try {
        await this.saveRestructuredOrgTree(history)
        notifications.success(this.SR_FieldRecordSystem.OrgUnitRelocateSuccessful)
      } catch (error) {
        console.error(error)
        notifications.criticalError(this.SR_FieldRecordSystem.OrgUnitRelocateFailed)
      } finally {
        this.saving = false
        this.showModal = false
        this.entityStructureEditing = false
      }
    }
  }
}
</script>

<style>
.easytree-drag-helper {
  z-index: 1051 !important;
}

#_st_clone_.easytree-node {
  z-index: 1051 !important;
}
</style>
