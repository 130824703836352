<template lang="html">
  <div class="header-settings" @click.stop>
    <SimpleSwitch v-model="showFieldCodes">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ShowFieldCodes</IxRes>
    </SimpleSwitch>
    <SimpleSwitch v-model="hideEmptyOrgs">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.HideEmptyOrgs</IxRes>
    </SimpleSwitch>
  </div>
</template>

<script>
import {mapUiFlags} from 'src/vue/areas/field-record-system/helpers/ui'

import SimpleSwitch from '@components/forms/SimpleSwitch'

export default {
  components: {
    SimpleSwitch
  },
  computed: {
    ...mapUiFlags([
      'dashboard.showFieldCodes',
      'dashboard.hideEmptyOrgs'
    ])
  }
}
</script>

<style lang="scss" scoped>
.header-settings {
  margin-top: 6px;
}
</style>
