<template lang="html">
  <FrsFieldSelection v-model="selectedFieldIds">
    <template #subtitle="{field}">
      <span v-if="samplingYearsByFieldId[field.id] === '1'" class="subtitle" title="year">
        <IxRes>bf.fieldItem.noProbingDate</IxRes>
      </span>
      <span v-else class="subtitle" title="year">
        <IxRes>bf.fieldItem.lastProbingDate</IxRes>:
        {{ samplingYearsByFieldId[field.id] }}
      </span>
    </template>
    <template #header="{field}">
      <div class="size">
        <span class="number">
          {{ numberOfPointsByFieldId[field.id] }}
        </span>
        <div>
          <span class="seperate-text-top">
            <IxRes>bf.orderCreation.fieldSelection.probing</IxRes>
          </span>
          <span class="seperate-text-bottom">
            <IxRes>bf.orderCreation.fieldSelection.points</IxRes>
          </span>
        </div>
      </div>
    </template>
  </FrsFieldSelection>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import FrsFieldSelection from '@frs/components/base/FrsFieldSelection'

export default {
  components: {
    FrsFieldSelection
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/basicFertilization/orderCreation', [
      'order.selectedFieldIds'
    ]),
    ...mapState('fieldRecordSystem/basicFertilization', {
      fieldStatisticsLookup: state => state.data.fieldStatisticsLookup.statistics
    }),
    samplingYearsByFieldId () {
      const lookup = {}

      for (const fieldId in this.fieldStatisticsLookup) {
        lookup[fieldId] = moment(this.fieldStatisticsLookup[fieldId].lastSamplingDate).year().toString()
      }

      return lookup
    },
    numberOfPointsByFieldId () {
      const lookup = {}

      for (const fieldId in this.fieldStatisticsLookup) {
        lookup[fieldId] = this.fieldStatisticsLookup[fieldId].numberProbingSites
      }

      return lookup
    }
  },
  created () {
    // https://youtrack.ixmap.eu/issue/GD-223
    // this.setFieldIds(this.subTreeFields.map(field => field.id))
  }
}
</script>

<style lang="scss" scoped>
.size {
  color: #DDD;
  margin-left: 0.25em;
  display: flex;

  div {
    display: flex;
    flex-direction: column;
  }

  .number {
    font-size: 3.5em;
    line-height: 0.9em;
    font-weight: 100;
  }

  .seperate-text-top {
    font-size: 1.5em;
    font-weight: 100;
  }

  .seperate-text-bottom {
    font-size: 1.5em;
    margin-top: -0.6em;
    font-weight: 100;
    align-self: flex-end;
  }
}
</style>
