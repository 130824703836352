<template lang="html">
  <div class="group-mode-selector">
    <label><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldSelectionGroupSelectorLabel</IxRes></label>
    <div class="btn-group">
      <ToggleButton v-model="valueProxy" value="orgUnit" required>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldSelectionOrgUnit</IxRes>
      </ToggleButton>
      <ToggleButton v-model="valueProxy" value="cultivationType" required>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldSelectionCultivationType</IxRes>
      </ToggleButton>
      <ToggleButton v-model="valueProxy" value="tags" required>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldSelectionTag</IxRes>
      </ToggleButton>
      <ToggleButton v-model="valueProxy" value="crop" required>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.FieldSelectionCrop</IxRes>
      </ToggleButton>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  props: {
    value: String
  },
  computed: {
    valueProxy: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.group-mode-selector {
  padding: 5px;
}
</style>
